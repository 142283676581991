import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import { ThemeProvider } from 'react-jss'
import { toJS } from 'mobx'

import { OrderBumpItem } from 'ui/order-bump-item/OrderBumpItem'
import Bundle from 'shared/components/bundle/Bundle'

import {
  ORDER_BUMPS_PREVIEW_TYPES,
  ORDER_BUMPS_VIEW_TYPES_KEYS,
  ORDER_BUMPS_VIEW_FIELD_KEY,
  ORDER_BUMPS_OLD_VIEW_KEY,
} from 'constants/orderBumpsShared.constants'
import { PRODUCT_TYPE_IDS } from 'constants/productsShared.constants'

import { get } from 'utils/lodash.utils'
import { useShopStores } from 'shop/hooks/use-store'

interface ProductUpsell {
  id: number
  prefs: {
    ownPlans: boolean
    required: boolean
  }
  upsell: {
    id: number
    pricingPlans: {
      id: number
    }[]
    slug: string
  }
  pricingPlans: {
    id: number
  }[]
  addonTheme: {
    prefs: {
      viewType: string
    }
  }
  ticket: {
    free: boolean
    pricingPlans: {
      id: number
    }[]
  }
  ticketDate: Record<string, never>
  ticketId: number
  ticketDateId: number
  ticketsCount: number
  upsellId: number
}

interface Props {
  previewMode: string
  isAllListedType: boolean
  lastIndex: boolean
  productUpsell: ProductUpsell
  countries: {
    alpha2: string | number
    name: string | number
  }[]
  position?: number
}

export const UpsellItemWrapper: React.FC<Props> = observer(function UpsellItemWrapper(props) {
  const { productUpsell, countries, previewMode, isAllListedType, lastIndex } = props
  const { paymentStore, productSettingsStore, currenciesStore, productUpsellsStore, themeStore } = useShopStores()
  const prefetchedUpsellProduct = productUpsellsStore.upsellProducts.find(
    (product) => product?.id === productUpsell.upsell?.id
  )

  const upsell = prefetchedUpsellProduct || { pricingPlans: [] }

  const getPricingPlanSuit = () => {
    const isOwnPlansActive = get(productUpsell, 'prefs.ownPlans', false)
    const isEvent = upsell.form === PRODUCT_TYPE_IDS.eventTickets

    if (isOwnPlansActive) {
      const customPrices = get(productUpsell, 'prices', [])
      if (customPrices.length > 0) {
        return customPrices.map((price) => ({
          priceId: price.id,
          ...price.pricingPlan,
        }))
      }
      return get(productUpsell, 'pricingPlans', [])
    }

    if (isEvent && productUpsell.ticket) {
      return productUpsell.ticket.pricingPlans
    }

    return upsell.pricingPlans
  }

  const getSelectedPricingPlan = () => {
    const { planId } = paymentStore.getSelectedUpsell(paymentStore.store?.upsell, productUpsell.id, 'productUpsellId')

    if (planId) {
      return (getPricingPlanSuit() || []).find((planItem) => String(planItem.id) === String(planId))
    }

    return ((getPricingPlanSuit() || [])[0] && getPricingPlanSuit()[0]) || {}
  }

  const upsellDetails = paymentStore.getSelectedUpsell(paymentStore.store?.upsell, productUpsell.id, 'productUpsellId')
  const { productUpsellId } = upsellDetails
  const isSelected = productUpsellId === productUpsell.id
  const isMobilePreview = previewMode === ORDER_BUMPS_PREVIEW_TYPES.mobile
  const Wrapper = isAllListedType ? 'span' : Fragment

  if (!upsell?.id) {
    return null
  }

  return (
    <>
      {productUpsell?.addonTheme?.prefs[ORDER_BUMPS_VIEW_FIELD_KEY] === ORDER_BUMPS_VIEW_TYPES_KEYS.six ? (
        <ThemeProvider
          theme={{
            ...productUpsell.addonTheme.prefs[ORDER_BUMPS_OLD_VIEW_KEY],
            isMobilePreview,
          }}
        >
          <Bundle
            isSelected={isSelected}
            countries={countries}
            upsell={upsell}
            productUpsell={productUpsell}
            productAddons={upsell?.productAddons}
            pricingPlans={getPricingPlanSuit()}
            ownPlans={productUpsell?.prefs?.ownPlans}
            coupon={paymentStore.store?.coupon}
            selectUpsell={paymentStore.selectUpsell}
            selectedPlanId={getSelectedPricingPlan()?.id}
            value={productUpsellId}
            upsellType={productSettingsStore.item.prefs?.upsellType}
            deselectUpsell={paymentStore.deselectUpsell}
            templateConfig={productUpsell?.addonTheme?.prefs[ORDER_BUMPS_OLD_VIEW_KEY]}
            currenciesStore={currenciesStore}
            themeStore={themeStore}
          />
        </ThemeProvider>
      ) : (
        <Wrapper>
          <OrderBumpItem
            config={toJS(productUpsell?.addonTheme?.prefs)}
            productItem={upsell}
            pricingPlans={getPricingPlanSuit()}
            convertToPrice={currenciesStore.convertToPrice}
            ownPlans={productUpsell?.prefs?.ownPlans}
            upsell={productUpsell}
            countries={countries}
            isSelected={isSelected}
            selectedPricingPlan={getSelectedPricingPlan()}
            handlePricingPlanChange={paymentStore.selectUpsell}
            upsellType={productSettingsStore.item.prefs?.upsellType}
            selectUpsell={paymentStore.selectUpsell}
            deselectUpsell={paymentStore.deselectUpsell}
            selectionValue={productUpsellId}
            coupon={paymentStore.store?.coupon}
            previewMode={previewMode}
            isAllListedType={isAllListedType}
            lastIndex={lastIndex}
            hidePlanSelection={paymentStore.showGroupedPricingPlans}
          />
        </Wrapper>
      )}
    </>
  )
})
