import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import { uuid } from 'utils/uuid'
import classNames from 'classnames'

import { useShopStores } from 'shop/hooks/use-store'

export const SummaryPricesDetails: React.FC = observer(function SummaryPricesDetails() {
  const { currenciesStore, paymentStore, countriesStore } = useShopStores()

  const convertToPrice = (value) => currenciesStore.convertToPrice(value, paymentStore.store?.activePlan?.currencyId)

  return (
    <div className='prices-details-info'>
      {paymentStore.buildedOrder?.orderRates?.slice(0, 1)?.map((orderRate, orderRateIndex) => {
        const { number, orderRatePrices } = orderRate

        const wrapCls = classNames('rate-wrap', {
          'rate-odd': orderRateIndex % 2 === 1,
          'rate-even': orderRateIndex % 2 === 0,
        })

        const sumByVat = {}
        let sumTotal = {
          net: 0,
          vat: 0,
          gross: 0,
          dVat: 0,
          dGross: 0,
        }
        let vatNotIncCount = 0
        let vatNotIncCountSec = 0

        return (
          <div className={wrapCls} key={number}>
            <div className='rate-title'>{I18n.t('react.shop.payment.order_details')}</div>
            <table className='table table-sm'>
              <thead>
                <tr>
                  <th scope='col'>{I18n.t('react.shop.payment.position')}</th>
                  <th scope='col' className='text-right'>
                    {I18n.t('react.shop.payment.quantity')}
                  </th>
                  <th scope='col' className='text-right'>
                    {I18n.t('react.shop.payment.item_net')}
                  </th>
                  <th scope='col' className='text-right'>
                    {I18n.t('react.shop.payment.vat_rate')}
                  </th>
                  <th scope='col' className='text-right'>
                    {I18n.t('react.shop.payment.item_gross')}
                  </th>
                  <th scope='col' className='text-right'>
                    {I18n.t('react.shop.payment.items_gross')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {orderRatePrices?.map((orp, index) => {
                  const { cfgs, rate, fees, discount } = orp.data
                  const rows = [rate ? 1 : 0, fees ? 1 : 0, discount ? 1 : 0].reduce((a, b) => a + b, 0)
                  const priceKey = cfgs.mustPayVat ? 'gross' : 'net'
                  const vatNotIncluded = cfgs.privateVatRate > 0 && !cfgs.mustPayVat
                  if (vatNotIncluded) {
                    vatNotIncCount += 1
                  }

                  const netRate = rate ? rate.net * rate.count : 0
                  const netFees = fees ? fees.net * fees.count : 0
                  const netDiscount = discount ? discount.net * discount.count : 0
                  const net = netRate + netFees + netDiscount

                  const vatRate = rate ? rate.vat * rate.count : 0
                  const vatFees = fees ? fees.vat * fees.count : 0
                  const vatDiscount = discount ? discount.vat * discount.count : 0
                  const vat = vatRate + vatFees + vatDiscount

                  const grossRate = rate ? rate.gross * rate.count : 0
                  const grossFees = fees ? fees.gross * fees.count : 0
                  const grossDiscount = discount ? discount.gross * discount.count : 0
                  const gross = grossRate + grossFees + grossDiscount

                  sumByVat[cfgs.transferVatRate] = sumByVat[cfgs.transferVatRate] || {
                    net: 0,
                    vat: 0,
                    gross: 0,
                    dVat: 0,
                    dGross: 0,
                  }
                  sumByVat[cfgs.transferVatRate] = {
                    net: sumByVat[cfgs.transferVatRate].net + net,
                    vat: sumByVat[cfgs.transferVatRate].vat + vat,
                    gross: sumByVat[cfgs.transferVatRate].gross + gross,
                    dVat: sumByVat[cfgs.transferVatRate].dVat + (cfgs.mustPayVat ? vat : 0),
                    dGross: sumByVat[cfgs.transferVatRate].dGross + (cfgs.mustPayVat ? gross : net),
                  }
                  sumTotal = {
                    net: sumTotal.net + net,
                    vat: sumTotal.vat + vat,
                    gross: sumTotal.gross + gross,
                    dVat: sumTotal.dVat + (cfgs.mustPayVat ? vat : 0),
                    dGross: sumTotal.dGross + (cfgs.mustPayVat ? gross : net),
                  }

                  return (
                    <Fragment key={uuid()}>
                      {rate && (
                        <tr>
                          <th rowSpan={rows} scope='row'>
                            {index + 1}
                          </th>
                          <td className='text-right'>{rate.count}</td>
                          <td className='text-right' translate='no'>
                            {convertToPrice(rate.net)}
                          </td>
                          <td className='text-right' translate='no'>
                            {cfgs.transferVatRate}% {vatNotIncluded && Array(vatNotIncCount).fill('*').join('')}
                          </td>
                          <td className='text-right' translate='no'>
                            {convertToPrice(rate[priceKey])}
                          </td>
                          <td className='text-right' translate='no'>
                            {convertToPrice(rate.count * rate[priceKey])}
                          </td>
                        </tr>
                      )}
                      {fees && (
                        <tr>
                          <td className='text-right' translate='no'>
                            {fees.count}
                          </td>
                          <td className='text-right' translate='no'>
                            {convertToPrice(fees.net)}
                          </td>
                          <td className='text-right' translate='no'>
                            {cfgs.transferVatRate}% {vatNotIncluded && Array(vatNotIncCount).fill('*').join('')}
                          </td>
                          <td className='text-right' translate='no'>
                            {convertToPrice(fees[priceKey])}
                          </td>
                          <td className='text-right' translate='no'>
                            {convertToPrice(fees.count * fees[priceKey])}
                          </td>
                        </tr>
                      )}
                      {discount && (
                        <tr>
                          <td className='text-right'>{discount.count}</td>
                          <td className='text-right' translate='no'>
                            {convertToPrice(discount.net)}
                          </td>
                          <td className='text-right' translate='no'>
                            {cfgs.transferVatRate}% {vatNotIncluded && Array(vatNotIncCount).fill('*').join('')}
                          </td>
                          <td className='text-right' translate='no'>
                            {convertToPrice(discount[priceKey])}
                          </td>
                          <td className='text-right' translate='no'>
                            {convertToPrice(discount.count * discount[priceKey])}
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  )
                })}
              </tbody>
            </table>
            <table
              className='table table-sm table-total'
              style={{
                width: '40%',
                float: 'right',
                clear: 'both',
              }}
            >
              <thead>
                <tr>
                  <th scope='col'>{I18n.t('react.shop.payment.total')}</th>
                  <th scope='col' className='text-right' translate='no'>
                    {convertToPrice(sumTotal.dGross)}
                  </th>
                </tr>
              </thead>
            </table>

            <div className='rate-title'>{I18n.t('react.shop.payment.vat_summary')}</div>
            <table
              className='table table-sm table-vat'
              style={{
                width: '50%',
                float: 'right',
                clear: 'both',
              }}
            >
              <thead>
                <tr>
                  <th scope='col' className='text-right'>
                    {I18n.t('react.shop.payment.vat_rate')}
                  </th>
                  <th scope='col' className='text-right'>
                    {I18n.t('react.shop.payment.sub_total_net')}
                  </th>
                  <th scope='col' className='text-right'>
                    {I18n.t('react.shop.payment.vat_amount')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(sumByVat).map((sumRowKey) => {
                  const sumRow = sumByVat[sumRowKey]

                  return (
                    <tr key={uuid()}>
                      <td className='text-right'>{sumRowKey}%</td>
                      <td className='text-right' translate='no'>
                        {convertToPrice(sumRow.net)}
                      </td>
                      <td className='text-right' translate='no'>
                        {convertToPrice(sumRow.dVat)}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <table
              className='table table-sm table-vat mb-0'
              style={{
                width: '60%',
                float: 'right',
                clear: 'both',
              }}
            >
              <thead>
                <tr>
                  <th scope='col'>{I18n.t('react.shop.payment.vat_total')}</th>
                  <th scope='col' className='text-right' translate='no'>
                    {convertToPrice(sumTotal.net)}
                  </th>
                  <th scope='col' className='text-right' translate='no'>
                    {convertToPrice(sumTotal.dVat)}
                  </th>
                </tr>
              </thead>
            </table>
            <div className='clearfix' />
            {orderRatePrices?.map((orp) => {
              const { cfgs, rate, fees, discount } = orp.data
              const vatNotIncluded = cfgs.privateVatRate > 0 && !cfgs.mustPayVat && cfgs.isBusiness && cfgs.withVatNo
              if (vatNotIncluded) {
                vatNotIncCountSec += 1
              }

              const vatRate = rate ? rate.vat * rate.count : 0
              const vatFees = fees ? fees.vat * fees.count : 0
              const vatDiscount = discount ? discount.vat * discount.count : 0
              const vat = convertToPrice(vatRate + vatFees + vatDiscount)

              if (vatNotIncluded) {
                const countryCode = cfgs.vatBaseCountry
                const countryName = countriesStore.getCountry(countryCode).name

                return (
                  <div className='mt-1' translate='no' key={cfgs.privateVatRate}>
                    {Array(vatNotIncCountSec).fill('*').join('')}
                    {I18n.t('react.shop.payment.reverse_charge', {
                      rate: `${cfgs.privateVatRate}%`,
                      amount: vat,
                      country: countryName,
                    })}
                  </div>
                )
              }

              return null
            })}
            <div className='clearfix' />
          </div>
        )
      })}
    </div>
  )
})
