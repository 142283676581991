import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import { cleanupHTMLStyleFonts } from 'utils/fonts.utils'

interface Props {
  type: string
  checked: boolean
  onClick: () => void
  paymentPageColor: string
  paymentBuyBtnText?: string
  label: string
  mandatory: boolean
  customCss?: string
}

export const Radio: React.FC<Props> = observer(function Radio(props) {
  const { checked, label, mandatory, onClick, type, paymentPageColor } = props

  const enabledStyle =
    checked && paymentPageColor
      ? {
          backgroundColor: paymentPageColor,
          borderColor: paymentPageColor,
        }
      : {}

  const requiredCustomCheckClasses = classNames({
    'required-custom-check-text': mandatory,
  })

  return (
    <Fragment>
      <label className={`custom-check ${type}`}>
        <span
          className={requiredCustomCheckClasses}
          dangerouslySetInnerHTML={{ __html: cleanupHTMLStyleFonts(label) }}
        />
        <Fragment>
          <input readOnly onClick={onClick} checked={checked || false} type={type} />
          <span className='custom-check-mark' style={enabledStyle}>
            <i className='boom' style={enabledStyle} />
          </span>
        </Fragment>
      </label>
    </Fragment>
  )
})
