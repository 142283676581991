import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import { createId } from '@elo-kit/utils/general.utils'

import { ORDER_BUMPS_POSITIONS_TYPES, PAYMENT_PAGE_LAYOUT } from 'constants/paymentPageTemplates.constants'
import { ORDER_BUMPS_VIEW_FIELD_KEY, ORDER_BUMPS_VIEW_TYPES_KEYS } from 'constants/orderBumpsShared.constants'
import { PRODUCT_TYPE_IDS } from 'constants/productsShared.constants'

import PricingPlansList from 'shared/components/pricing-plans/PricingPlansList'
import { useShopStores } from 'shop/hooks/use-store'

import { UpsellItemWrapper } from './UpsellItemWrapper'

interface Props {
  upsellPosition: string
  activeUpsellPosition: string
  activeLayout: string
  previewMode: string
}

// TODO: move paymentStore.trackPaymentVisit to better place
export const Upsells: React.FC<Props> = observer(function Upsells(props) {
  const { upsellPosition, activeUpsellPosition, activeLayout, previewMode } = props
  const { paymentStore, productUpsellsStore, productSettingsStore, trackingCodesStore, countriesStore } =
    useShopStores()

  const isPositionCorrect = upsellPosition === activeUpsellPosition

  useEffect(() => {
    if (!isPositionCorrect) {
      return
    }

    if (isPositionCorrect) {
      paymentStore.trackPaymentVisit()
    }

    return () => {
      productUpsellsStore.resetList()
      productSettingsStore.resetItem()
      trackingCodesStore.setTrackEventData()
    }
  }, [])

  const shouldShowUpsells =
    productSettingsStore.item?.prefs?.upsell && productUpsellsStore.list.length > 0 && !paymentStore.invoice?.token

  const renderUpsellTitle = () => {
    const productSettingsPrefs = productSettingsStore.item?.prefs

    if (!productSettingsPrefs?.upsellTitle) {
      return null
    }

    return <div className='container-title'>{productSettingsPrefs?.upsellTitle}</div>
  }

  const renderUpsellList = () => {
    const isAllListedType = productUpsellsStore.list
      ?.map((item) => item?.addonTheme?.prefs[ORDER_BUMPS_VIEW_FIELD_KEY] === ORDER_BUMPS_VIEW_TYPES_KEYS.seven)
      .every(Boolean)

    return productUpsellsStore.list?.map((productUpsell, index) => {
      const { prefs, id, upsell, pricingPlans = [], addonTheme, ticket } = productUpsell
      const { prefs: addonThemePrefs } = addonTheme || {}
      const { ownPlans } = prefs || {}
      const { pricingPlans: bundlePricingPlans = [], free, form } = upsell || {}

      const isTicket = form === PRODUCT_TYPE_IDS.eventTickets
      const lastIndex = index === productUpsellsStore.list?.length - 1
      const hideUpsell =
        (ownPlans && !pricingPlans.length) ||
        (!ownPlans && !bundlePricingPlans.length && !free && !isTicket) ||
        (isTicket && !ticket?.pricingPlans.length && !ticket?.free) ||
        !addonThemePrefs?.viewType

      if (hideUpsell) {
        return null
      }

      return (
        <UpsellItemWrapper
          key={createId(id, index)}
          productUpsell={productUpsell}
          countries={countriesStore.list}
          previewMode={previewMode}
          isAllListedType={isAllListedType}
          lastIndex={lastIndex}
        />
      )
    })
  }

  if (!isPositionCorrect) {
    return null
  }

  const isAllListedType = productUpsellsStore.list
    .map((item) => item?.addonTheme?.prefs[ORDER_BUMPS_VIEW_FIELD_KEY] === ORDER_BUMPS_VIEW_TYPES_KEYS.seven)
    .every(Boolean)

  const upsellsListClasses = classNames('upsells-list', {
    'upsells-list-container': isAllListedType,
  })

  return (
    shouldShowUpsells && (
      <div
        className={classNames('upsells fr-view', {
          'layout-default': activeLayout === PAYMENT_PAGE_LAYOUT.default,
          'layout-simplified': activeLayout === PAYMENT_PAGE_LAYOUT.simplified,
          'layout-two-steps': activeLayout === PAYMENT_PAGE_LAYOUT.twoSteps,
          'below-product-details': activeUpsellPosition === ORDER_BUMPS_POSITIONS_TYPES.belowProductDetails,
          'below-payer-details': activeUpsellPosition === ORDER_BUMPS_POSITIONS_TYPES.belowPayerDetails,
          'below-payment-details': activeUpsellPosition === ORDER_BUMPS_POSITIONS_TYPES.belowPaymentMethods,
        })}
      >
        {renderUpsellTitle()}
        <div className={upsellsListClasses}>{renderUpsellList()}</div>
        {!!paymentStore.showGroupedPricingPlans && (
          <div className='widget-wrap plans-list-container'>
            <PricingPlansList
              withoutNet
              pricingPlans={paymentStore.groupedPricingPlans}
              selectedId={paymentStore.selectedGroupedPricingPlan}
              forForm
              coupon={paymentStore.store?.coupon?.data?.upsells && paymentStore.store?.coupon}
              onSelectPlan={(planId) =>
                paymentStore.handleGroupingPlanSelect(
                  paymentStore.groupedPricingPlans.findIndex((item) => item.id === planId)
                )
              }
              keepEnabled
            />
          </div>
        )}
      </div>
    )
  )
})
