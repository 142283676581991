import React, { Fragment } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'

import { LoadingMask } from '@elo-kit/components/loading-mask/LoadingMask'

import { EMPTY_TEXT_EDITOR } from 'constants/courses.constants'
import { ORDER_BUMPS_POSITIONS_TYPES, PAYMENT_PAGE_LAYOUT } from 'constants/paymentPageTemplates.constants'
import { EMBED_TYPES } from 'constants/embeddableItemsShared.constants'
import { ORDER_BUMPS_PREVIEW_TYPES } from 'constants/orderBumpsShared.constants'
import { useShopStores } from 'shop/hooks/use-store'
import { cleanupHTMLStyleFonts } from 'utils/fonts.utils'

import { Purchase } from '../purchase'
import { Summary } from '../summary'
import { Upsells } from '../upsells'
import { PayerForms } from '../payerForms'
import { ShopHeader } from '../header'
import { Tickets } from '../tickets'
import { Attendees } from '../attendees'
import { InvoiceBanner } from '../invoice-banner/InvoiceBanner'
import { TermsAndBuyButton } from '../TermsAndBuyButton'
import { SessionTimer } from '../session-timer/SessionBasketTimer'
import { SocialProofBubbleContainer } from '../social-proof-bubble/SocialProofBubble'

interface Props {
  activeUpsellPosition?: string
  orderBumpPreviewMode?: string
  isTemplatePreview?: boolean
  manageLink?: string
  iframeData?: {
    shortDescription: string
    checkoutHtml: string
    checkoutHtmlFooter: string
    pricingPlans: {
      id: number
    }[]
    sessionTimerSettings: {
      isSessionTimerActive: boolean
      timerTime: string
      timerText: string
      timerBackgroundColor: string
      timerFontColor: string
      backgroundImageActive: boolean
      backgroundImage: {
        coverId: string
        coverUrl: string
      }
    }
    free: boolean
  }
  cabinetPreview?: boolean
  themeStore
}

interface UpsellProps {
  upsellPosition: string
  previewMode?: string
  activeUpsellPosition: string
  activeLayout?: string
}

const UpsellComponent = ({
  upsellPosition,
  previewMode = ORDER_BUMPS_PREVIEW_TYPES.mobile,
  activeUpsellPosition,
}: UpsellProps) => (
  <Upsells
    upsellPosition={upsellPosition}
    activeLayout={PAYMENT_PAGE_LAYOUT.default}
    activeUpsellPosition={activeUpsellPosition}
    previewMode={previewMode}
  />
)

export const Default: React.FC<Props> = observer(function Default(props) {
  const { activeUpsellPosition, orderBumpPreviewMode, isTemplatePreview, manageLink, iframeData, themeStore } = props
  const { paymentStore, userStore } = useShopStores()

  const isModalEmbed = paymentStore.store?.props?.embedType === EMBED_TYPES.modal
  const wrapperClasses = classNames({
    'embeded-page-wrapper': isModalEmbed,
  })
  const customColumnClasses = classNames('custom-col', {
    'full-column': paymentStore.invoice?.token,
  })

  return (
    <div className={wrapperClasses}>
      <SessionTimer
        timer={iframeData?.sessionTimerSettings || paymentStore.product?.sessionTimerSettings}
        productId={paymentStore.product?.id}
        userId={userStore.item?.id}
      />

      <div
        className='fr-view'
        dangerouslySetInnerHTML={{
          __html: cleanupHTMLStyleFonts(iframeData?.checkoutHtml || paymentStore.product?.checkoutHtml) || '',
        }}
      />

      {paymentStore.invoice?.token && <InvoiceBanner order={paymentStore.buildedOrder} manageLink={manageLink} />}

      <ShopHeader isTemplatePreview={isTemplatePreview} iframeData={iframeData} />

      <Tickets />

      <UpsellComponent
        upsellPosition={ORDER_BUMPS_POSITIONS_TYPES.belowProductDetails}
        previewMode={orderBumpPreviewMode}
        activeUpsellPosition={activeUpsellPosition}
      />

      {paymentStore.proposeAttendees ? (
        <Fragment>
          <div className='widget-wrap'>
            <div className='widget-box'>
              <div className={customColumnClasses}>
                <PayerForms />
                <UpsellComponent
                  upsellPosition={ORDER_BUMPS_POSITIONS_TYPES.belowPayerDetails}
                  activeUpsellPosition={activeUpsellPosition}
                />
              </div>
              {!paymentStore.invoice?.token && (
                <div className='custom-col brl-lg'>
                  <div className='widget-line d-lg-none' />
                  <Attendees />
                </div>
              )}
            </div>
          </div>
          <div className='widget-line' />
          <div className='widget-wrap'>
            <div className='widget-box'>
              {paymentStore.store.free ? (
                <div className='custom-col offset-lg-3'>
                  <Summary />
                  <Purchase
                    upsellItems={
                      <UpsellComponent
                        upsellPosition={ORDER_BUMPS_POSITIONS_TYPES.belowPaymentMethods}
                        activeUpsellPosition={activeUpsellPosition}
                      />
                    }
                    themeStore={themeStore}
                  />
                </div>
              ) : (
                <Fragment>
                  <div className='custom-col'>
                    <Summary />
                  </div>
                  <div className='custom-col brl-lg'>
                    <div className='widget-line d-lg-none' />
                    <Purchase
                      upsellItems={
                        <UpsellComponent
                          upsellPosition={ORDER_BUMPS_POSITIONS_TYPES.belowPaymentMethods}
                          activeUpsellPosition={activeUpsellPosition}
                        />
                      }
                      themeStore={themeStore}
                    />
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </Fragment>
      ) : (
        <div className='widget-wrap'>
          {paymentStore.displaySummary ? (
            <div className='widget-box payers-container'>
              <div className='custom-col'>
                <PayerForms isTemplatePreview={isTemplatePreview} />
                <UpsellComponent
                  upsellPosition={ORDER_BUMPS_POSITIONS_TYPES.belowPayerDetails}
                  activeUpsellPosition={activeUpsellPosition}
                />
              </div>
              <div className='custom-col brl-lg'>
                <Purchase withWidgetLine withoutTermsAndBuy iframeFree={iframeData?.free} themeStore={themeStore} />
                <Summary isTemplatePreview={isTemplatePreview} iframeFree={iframeData?.free} />
                <div className='purchase'>
                  <TermsAndBuyButton
                    iframeFree={iframeData?.free}
                    upsellItems={
                      <UpsellComponent
                        upsellPosition={ORDER_BUMPS_POSITIONS_TYPES.belowPaymentMethods}
                        activeUpsellPosition={activeUpsellPosition}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            <Fragment>
              {paymentStore.buildedOrderLoading && <LoadingMask />}
              {!paymentStore.buildedOrderLoading && (
                <div className='widget-box payers-container'>
                  <div className='custom-col offset-lg-3'>
                    <PayerForms />
                    <UpsellComponent
                      upsellPosition={ORDER_BUMPS_POSITIONS_TYPES.belowPayerDetails}
                      activeUpsellPosition={activeUpsellPosition}
                    />
                    <Purchase
                      upsellItems={
                        <UpsellComponent
                          upsellPosition={ORDER_BUMPS_POSITIONS_TYPES.belowPaymentMethods}
                          activeUpsellPosition={activeUpsellPosition}
                        />
                      }
                      themeStore={themeStore}
                    />
                  </div>
                </div>
              )}
            </Fragment>
          )}
        </div>
      )}
      {(paymentStore.product.checkoutHtmlFooter !== EMPTY_TEXT_EDITOR ||
        (iframeData?.checkoutHtmlFooter && iframeData?.checkoutHtmlFooter !== EMPTY_TEXT_EDITOR)) && (
        <div
          className='fr-view'
          dangerouslySetInnerHTML={{
            __html:
              cleanupHTMLStyleFonts(iframeData?.checkoutHtmlFooter || paymentStore.product.checkoutHtmlFooter) || '',
          }}
        />
      )}

      {paymentStore.product.socialProofBubbleActivated && (
        <SocialProofBubbleContainer bubbleData={paymentStore.product.socialProofBubble} />
      )}
    </div>
  )
})
