import React, { useEffect, useState } from 'react'
import moment from 'moment'
import classNames from 'classnames'

import { SocialProofBubble } from '@elo-kit/components/social-proof-bubble/SocialProofBubble'

import { BUBBLE_TYPE } from 'constants/productsShared.constants'

import { useShopStores } from 'shop/hooks/use-store'

const DEFAULT_BUBBLE_TIME = 10000

interface Bubble {
  badge?: string
  title?: string
  text?: string
  time?: string
}

const msToTime = (ms) => {
  const diff = moment().diff(ms, 'minutes')
  const days = Math.floor(diff / 1440)
  const hours = Math.floor(diff / 60)

  if (diff > 1440) {
    return I18n.t('react.shared.checkout.days_ago', { count: days })
  } else if (diff > 60) {
    return I18n.t('react.shared.checkout.hours_ago', { count: hours })
  } else {
    return I18n.t('react.shared.checkout.minutes_ago', { count: diff })
  }
}

export const SocialProofBubbleContainer = ({ bubbleData }) => {
  const [bubble, setBubble] = useState<Bubble>({})
  const [bubbleOrder, setBubbleOrder] = useState<number>(0)
  const [hideBubble, setHideBubble] = useState<boolean>(false)

  const { countriesStore } = useShopStores()

  const { pauseTimeSeconds, displayTimeSeconds, designSettings, bubbleType, data } = bubbleData || {}
  const { designType, badgeStyle, backgroundColor, textColor } = designSettings || {}
  const [isRecentPurchase, isTotalPurchases, isTotalViews] = [
    BUBBLE_TYPE.recentPurchase,
    BUBBLE_TYPE.totalPurchases,
    BUBBLE_TYPE.totalViews,
  ].map((type) => bubbleType === type)

  const socialProofBubbleClasses = classNames(`checkout-page-bubble 
  social-proof-bubble__${designType}
  social-proof-bubble__${badgeStyle}`)

  useEffect(() => {
    let startBubble
    let stopBubble
    let badge = ''
    let title = ''
    let text = ''
    let time = ''

    if (
      !hideBubble &&
      isRecentPurchase &&
      data.length &&
      data[bubbleOrder]?.firstName &&
      data[bubbleOrder]?.countryCode
    ) {
      const countryName = countriesStore.getCountry(data[bubbleOrder].countryCode).name

      badge = data[bubbleOrder].firstName[0]
      title = `${data[bubbleOrder].firstName} ${I18n.t('react.shared.checkout.from').toLowerCase()} ${countryName}`
      text = I18n.t('react.shared.checkout.bought_product')
      time = msToTime(data[bubbleOrder].purchasedAt)

      startBubble = setTimeout(
        () => {
          setBubble({ badge, title, text, time })

          stopBubble = setTimeout(() => {
            setBubble({})

            if (bubbleOrder < data.length) {
              setBubbleOrder((prev) => prev + 1)
            } else if (bubbleOrder === data.length && data.length !== 1) {
              setBubbleOrder(0)
            }
          }, displayTimeSeconds * 1000)
        },
        !bubbleOrder ? DEFAULT_BUBBLE_TIME : pauseTimeSeconds * 1000
      )
    }

    if (!hideBubble && data && (isTotalPurchases || isTotalViews)) {
      if (isTotalPurchases) {
        badge = data.totalPurchases || ''
        title = I18n.t('react.shared.checkout.people', { count: data.totalPurchases })
        text = I18n.t('react.shared.checkout.purchased_product')
        time = `30 ${I18n.t('react.shared.common.days')}`
      } else if (isTotalViews) {
        badge = data.totalViews || ''
        title = I18n.t('react.shared.checkout.people', { count: data.totalViews })
        text = I18n.t('react.shared.checkout.viewing_page')
        time = I18n.t('react.shared.now')
      }

      startBubble = setTimeout(() => {
        setBubble({ badge, title, text, time })

        stopBubble = setTimeout(() => {
          setBubble({})
        }, displayTimeSeconds * 1000)
      }, DEFAULT_BUBBLE_TIME)
    }

    return () => {
      startBubble && clearTimeout(startBubble)
      stopBubble && clearInterval(stopBubble)
    }
  }, [bubbleOrder])

  const { badge, title, text, time } = bubble

  return (
    !hideBubble &&
    badge && (
      <SocialProofBubble
        customClasses={socialProofBubbleClasses}
        hideBubble={() => setHideBubble(true)}
        bigBadge={isRecentPurchase}
        badgeBackground={backgroundColor}
        badgeColor={textColor}
        {...{ badge, title, text, time }}
      />
    )
  )
}
