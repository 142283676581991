import React, { Fragment, useState } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import { FREE_PRICE } from 'constants/general.constants'
import { PAYMENT_PLANS } from 'constants/pricingPlans.constants'
import { PRODUCT_TYPE_IDS, TICKET_VIEW } from 'constants/productsShared.constants'
import { DATE_FORMATS, TIME_FORMATS } from '@elo-kit/constants/dateTime.constants'
import { PAYMENT_FORMS } from 'constants/paymentSettingShared.constants'
import { ACTIVE_PROFILES } from 'constants/profile.constants'

import PricingPlansList from 'shared/components/pricing-plans/PricingPlansList'
import { OldPrice } from '@elo-kit/components/elo-ui/old-price/OldPrice'
import { CheckboxField } from '@elo-kit/components/form/checkbox-field/CheckboxField'
import ButtonInputGroupPreview from '@elo-kit/components/button-input-group/ButtonInputGroup'
import { Ellipsis } from '@elo-kit/components/ellipsis/Ellipsis'
import { Countdown } from '@elo-kit/components/pricing-plans-list/countdown/Countdown'
import { MultipleTicketDateSelector } from 'ui/multiple-ticket-date-selector/MultipleTicketDataSelector'

import { isWindows } from 'utils/browsers.utils'
import { getFormattedTicketDates, getFormattedTicketLocation } from 'utils/tickets.utils'
import { checkIsCouponApplied } from '@elo-kit/utils/coupons.utils'
import { formatDateTime, getGMTTimezone } from '@elo-kit/utils/dateTime.utils'
import { mapToIdsArray } from '@elo-kit/utils/helpers.utils'
import { profile } from 'utils/profileHelper.utils'
import { useShopStores } from 'shop/hooks/use-store'
import { TicketDate } from 'shop/types/ticket'

interface Props {
  twoStepsAdvanced?: boolean
}

const MAX_ADDITIONAL_TEXT_LENGTH = 160
const MAX_ADDITIONAL_SELECTOR_TEXT_LENGTH = 64

interface AdditionalTextLabel {
  additionalText: string
  maxLength?: number
  grey?: boolean
}

export const Tickets: React.FC<Props> = observer(function Tickets(props) {
  const { twoStepsAdvanced } = props
  const { currenciesStore, paymentStore, countriesStore, themeStore } = useShopStores()
  const [selectedTicketDates, setSelectedTicketDates] = useState({})

  const { tickets } = paymentStore.store?.props || {}

  const {
    paymentCountdownBgColor,
    paymentCountdownBorderColor,
    paymentCountdownTextColor,
    paymentCountdownTimeColor,
    countdownText,
  } = themeStore.ppTemplate?.theme || {}

  const ifTicketDate = (tickets, ticketId, ticketDateId) => {
    const ticket = tickets[ticketId]
    return ticket && ticket.ticketDates && ticket.ticketDates[ticketDateId]
  }

  const isShopProfile = profile.profileType === ACTIVE_PROFILES.shop
  const paymentTickets = paymentStore.invoice?.token ? paymentStore.invoice?.tickets : paymentStore.product?.tickets
  const hideInvoicePricingPlans =
    paymentStore.invoice?.token && paymentStore.buildedOrder?.paymentForm === PAYMENT_FORMS.payLater

  const urlTicketIds = tickets?.map((item) => String(item.ticketId))
  const urlTicketDates = tickets?.reduce(
    (prevItem, item) => [...prevItem, ...(Object.values(item.ticketDates || []) || [])],
    []
  )
  const urlTicketDateIds = urlTicketDates?.map((item) => String(item.ticketDateId))

  const ticketsToShow = !!urlTicketDates?.length
    ? paymentTickets.reduce((prevTicket, ticket) => {
        const filteredTicketDates = ticket.ticketDates.filter((item) => urlTicketDateIds?.includes(String(item.id)))
        const currentTicket = tickets.find((item) => String(item.ticketId) === String(ticket.id))

        if (urlTicketIds?.includes(String(ticket.id))) {
          return [
            ...prevTicket,
            {
              ...ticket,
              ticketDates: filteredTicketDates.length ? filteredTicketDates : ticket.ticketDates,
              displayedPlansId: currentTicket?.displayedPlanIds,
              planId: currentTicket?.planId,
            },
          ]
        }

        return prevTicket
      }, [])
    : paymentTickets

  if (paymentStore.product?.form !== PRODUCT_TYPE_IDS.eventTickets || ticketsToShow?.length === 0) {
    return null
  }

  const color = themeStore.ppTemplate?.theme?.paymentPageColor
  const enabledStyle = color ? { color } : {}

  const updateSelectedTicketDates = (ticketDate: TicketDate, ticketId: number) => {
    setSelectedTicketDates({
      ...selectedTicketDates,
      [ticketId]: [...(selectedTicketDates[ticketId] || []), ticketDate],
    })

    paymentStore.handleTicketDateChange({ target: { checked: true } }, ticketId, ticketDate?.id)
  }

  const removeSelectedTicketDate = (ticketDateId: number, ticketId: number) => {
    const filteredTicketDates = selectedTicketDates[ticketId]?.filter((td) => td.id !== ticketDateId)

    setSelectedTicketDates({
      ...selectedTicketDates,
      [ticketId]: filteredTicketDates,
    })

    paymentStore.handleTicketDateChange({ target: { checked: false } }, ticketId, ticketDateId)
  }

  const getAdditionalText = ({ additionalText, maxLength = MAX_ADDITIONAL_TEXT_LENGTH, grey }: AdditionalTextLabel) => {
    const additionalTextClasses = classNames({
      'ticket-date-time ticket-date-time--bold': !grey,
      'ticket-date-time--grey': grey,
    })

    return (
      <span className={additionalTextClasses}>
        <Ellipsis maxLength={maxLength}>{additionalText}</Ellipsis>
      </span>
    )
  }

  const getTicketDateLabel = (ticketDate: TicketDate, inSelector = true) => {
    const { startDate, endDate, timeZoneName, eventForm, additionalText } = ticketDate || {}

    return (
      <div className='ticket-date-desktop'>
        {eventForm === 'with_dates' ? (
          <Fragment>
            <div className='ticket-date-time'>
              <span>
                <span className='ticket-date-time--bold'>
                  {formatDateTime(startDate, DATE_FORMATS.DDMMYYYY, timeZoneName)}{' '}
                </span>
                <span className='ticket-date-time--grey'>
                  {formatDateTime(startDate, TIME_FORMATS.HHmm, timeZoneName)}
                </span>
                {!endDate && <span className='ticket-date-time--country-name'> {getGMTTimezone(timeZoneName)}</span>}
              </span>
              {!!endDate && (
                <>
                  <span className='ticket-date-time--grey'> - </span>
                  <span>
                    <span className='ticket-date-time--bold'>
                      {formatDateTime(endDate, DATE_FORMATS.DDMMYYYY, timeZoneName)}{' '}
                    </span>
                    <span className='ticket-date-time--grey'>
                      {formatDateTime(endDate, TIME_FORMATS.HHmm, timeZoneName)}
                    </span>
                    <span className='ticket-date-time--grey'> {getGMTTimezone(timeZoneName)}</span>
                  </span>
                </>
              )}
            </div>
            <div className='ticket-date-time__additional-text'>
              {getAdditionalText({
                additionalText,
                maxLength: inSelector ? MAX_ADDITIONAL_SELECTOR_TEXT_LENGTH : MAX_ADDITIONAL_TEXT_LENGTH,
                grey: true,
              })}
            </div>
          </Fragment>
        ) : (
          getAdditionalText({ additionalText })
        )}
      </div>
    )
  }

  const getMobileTicketDateLabel = (ticketDate: TicketDate, inSelector = true) => {
    const { startDate, endDate, timeZoneName, eventForm, additionalText } = ticketDate || {}

    return (
      <div className='ticket-date-mobile'>
        {eventForm === 'with_dates' ? (
          <Fragment>
            <div className='ticket-date-time'>
              <div>
                <span className='ticket-date-time--bold'>
                  {formatDateTime(startDate, DATE_FORMATS.DDMMYYYY, timeZoneName)}{' '}
                </span>
                <span className='ticket-date-time--grey'>
                  {formatDateTime(startDate, TIME_FORMATS.HHmm, timeZoneName)}
                </span>
              </div>
              {!!endDate && (
                <div>
                  <span className='ticket-date-time--bold'>
                    {formatDateTime(endDate, DATE_FORMATS.DDMMYYYY, timeZoneName)}{' '}
                  </span>
                  <span className='ticket-date-time--grey'>
                    {formatDateTime(endDate, TIME_FORMATS.HHmm, timeZoneName)}
                  </span>
                </div>
              )}
            </div>
            <div className='ticket-date-time__additional-text'>
              {getAdditionalText({
                additionalText,
                maxLength: inSelector ? MAX_ADDITIONAL_SELECTOR_TEXT_LENGTH : MAX_ADDITIONAL_TEXT_LENGTH,
                grey: true,
              })}
            </div>
          </Fragment>
        ) : (
          getAdditionalText({ additionalText })
        )}
      </div>
    )
  }

  const isDropDownView = paymentStore.product?.ticketView === TICKET_VIEW.dropDown

  return (
    <div className='payment-tickets'>
      {ticketsToShow?.map((ticket) => {
        const { free, pricingPlans, limitVisibilityType, showLimit } = ticket

        const ticketDatesOptions = isDropDownView
          ? ticket?.ticketDates?.map((ticketDate) => ({
              value: ticketDate?.id,
              label: getTicketDateLabel(ticketDate),
            }))
          : null

        const dropDownTicketDates = selectedTicketDates?.[ticket?.id] || []
        const selectedTicketDateIds = isDropDownView ? mapToIdsArray(dropDownTicketDates) : null

        const filteredTicketDateOptions = isDropDownView
          ? ticketDatesOptions.reduce((prevTicket, ticket) => {
              if (selectedTicketDateIds.includes(ticket.value)) {
                return prevTicket
              } else {
                return [...prevTicket, ticket]
              }
            }, [])
          : null

        const firstPricingPlan = pricingPlans[0]

        const ticketPlans = paymentStore.getProductPlansToShow(null, ticket?.id, ticket)
        const oneTimePlan =
          !free && pricingPlans?.length === 1 && firstPricingPlan.form === PAYMENT_PLANS.oneTime && firstPricingPlan
        const isOneTimeWithTestPeriod = oneTimePlan && ticketPlans[0].testPeriodEnabled

        const { prefs: planPrefs, currencyId, useNetPrice, activateCountdown, validTill } = oneTimePlan || {}
        const showPlans = !free && (!oneTimePlan || isOneTimeWithTestPeriod) && pricingPlans?.length > 0
        const plansToShow = showPlans && ticketPlans
        const couponApplied = checkIsCouponApplied(paymentStore.store?.coupon, firstPricingPlan?.id, ticket?.id)
        const firstPriceClassName = classNames('price', {
          'price-crossed': couponApplied,
          'price-crossed--windows': couponApplied && isWindows,
        })
        const eventTicketTopClasses = classNames('event-ticket-top', {
          'event-ticket-top--margin-bottom': !paymentStore.invoice?.token && isDropDownView,
        })
        const eventTicketClasses = classNames('event-ticket', {
          'event-ticket--two-steps-advanced': twoStepsAdvanced,
        })
        const eventTicketHeaderClasses = classNames({
          'event-ticket-countdown': isShopProfile && activateCountdown,
        })

        const showHeaderPrice = !hideInvoicePricingPlans && oneTimePlan && !isOneTimeWithTestPeriod
        const [isExact, isApproximate] = ['exact', 'approximate'].map((item) => item === limitVisibilityType)

        return (
          <Fragment key={ticket?.id}>
            <div className={eventTicketClasses}>
              <div className='product-content'>
                <div
                  className={`ticket-content ${
                    showPlans ? 'ticket-content-half-screen' : 'ticket-content-full-screen'
                  }`}
                >
                  <div className='ticket-content--in-row'>
                    <div className={eventTicketTopClasses}>
                      <div className='event-ticket-name'>{ticket?.name}</div>
                      <div className='event-ticket-location'>
                        <i className='fas fa-map-marker-alt' />
                        {getFormattedTicketLocation(ticket, countriesStore.list)}
                      </div>
                    </div>
                    {free && (
                      <div className='header-price'>
                        <span className='price'>{FREE_PRICE}</span>
                      </div>
                    )}
                    {showHeaderPrice && (
                      <div className={eventTicketHeaderClasses}>
                        <div className='header-price'>
                          <span className={firstPriceClassName} translate='no'>
                            {currenciesStore.convertToPrice(planPrefs?.price, currencyId)}
                            {useNetPrice && <i>{I18n.t('react.shared.netto')}</i>}
                          </span>
                          {!!Number(planPrefs?.oldPrice) && (
                            <OldPrice isWindows={isWindows}>
                              {currenciesStore.convertToPrice(planPrefs?.oldPrice, currencyId)}
                            </OldPrice>
                          )}
                          {couponApplied && (
                            <Fragment>
                              <div className='clearfix' />
                              <div className='coupon-applied'>
                                <i className='fas fa-check' />
                                {I18n.t('react.shared.coupon.applied')}
                              </div>
                            </Fragment>
                          )}
                        </div>
                        {isShopProfile && activateCountdown && showHeaderPrice && (
                          <Countdown
                            paymentCountdownBgColor={paymentCountdownBgColor}
                            paymentCountdownBorderColor={paymentCountdownBorderColor}
                            paymentCountdownTextColor={paymentCountdownTextColor}
                            paymentCountdownTimeColor={paymentCountdownTimeColor}
                            countdownText={countdownText}
                            validTill={validTill}
                          />
                        )}
                      </div>
                    )}
                  </div>

                  {!paymentStore.invoice?.token && isDropDownView ? (
                    <>
                      <MultipleTicketDateSelector
                        value=''
                        options={filteredTicketDateOptions}
                        onChange={(ticketDateId) => {
                          updateSelectedTicketDates(
                            ticket.ticketDates.find((td) => td.id === ticketDateId),
                            ticket?.id
                          )
                        }}
                        placeholder={I18n.t('react.shop.payment.ticket.select_dates')}
                        className='multiple-ticket-date-field--max-with-400'
                      />

                      {dropDownTicketDates?.map((td) => {
                        const selectedTicket = ifTicketDate(paymentStore.store?.event?.tickets, ticket?.id, td?.id)

                        if (td?.limit === 0) {
                          return null
                        }

                        return (
                          <div className={`ticket-metadata ${selectedTicket ? 'selected' : ''}`} key={td?.id}>
                            <div className='ticket-data ticket-data--start'>
                              <div className='ticket-data__flex-container'>
                                <div className='ticket-count--mobile-remove-icon'>
                                  <i
                                    className='far fa-trash-alt'
                                    onClick={() => removeSelectedTicketDate(td?.id, ticket?.id)}
                                  />
                                </div>
                                <div>
                                  <span>{getTicketDateLabel(td, false)}</span>
                                  <span>{getMobileTicketDateLabel(td, false)}</span>
                                  <div className='ticket-data__count-container'>
                                    {td?.limit && (
                                      <div className='limit-help limit-help--without-margin'>
                                        {I18n.t('react.shop.payment.form.ticket.max')}: {td.limit}
                                      </div>
                                    )}
                                    {td?.minToBeBought && td.minToBeBought !== 1 && (
                                      <div className='limit-help limit-help--without-margin'>
                                        {I18n.t('react.shop.payment.form.ticket.min')}: {td.minToBeBought}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className='ticket-count'>
                                {td?.limit && (
                                  <span className='limit-help'>
                                    {I18n.t('react.shop.payment.form.ticket.max')}: {td.limit}
                                  </span>
                                )}
                                {td?.minToBeBought && td.minToBeBought !== 1 ? (
                                  <span className='limit-help'>
                                    {I18n.t('react.shop.payment.form.ticket.min')}: {td.minToBeBought}
                                  </span>
                                ) : (
                                  ''
                                )}
                                <ButtonInputGroupPreview
                                  disabled={!selectedTicket}
                                  activateOnClick
                                  min={td?.minToBeBought}
                                  max={td?.limit}
                                  value={selectedTicket?.count || 0}
                                  data-ticket-id={ticket?.id}
                                  data-ticket-date-id={td?.id}
                                  onChange={(count) =>
                                    paymentStore.handleTicketsCountChange(ticket?.id, td?.id, count, !!selectedTicket)
                                  }
                                  style={enabledStyle}
                                  key={selectedTicket?.count}
                                />
                                <div className='ticket-count--desktop-remove-icon'>
                                  <i
                                    className='far fa-trash-alt'
                                    onClick={() => removeSelectedTicketDate(td?.id, ticket?.id)}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className='ticket-date-time__additional-text--tablet'>
                              {getAdditionalText({
                                additionalText: td?.additionalText,
                                maxLength: MAX_ADDITIONAL_TEXT_LENGTH,
                                grey: true,
                              })}
                            </div>

                            {showLimit && (
                              <div className='stock-count'>
                                {((isExact && td.inStock) || (td.inStock && td.inStock <= 10)) && (
                                  <div>{I18n.t('react.shop.payment.exact_limit', { limitForSale: td.inStock })}</div>
                                )}
                                {isApproximate && td.inStock > 10 && (
                                  <div>{I18n.t('react.shop.payment.approximate_limit')}</div>
                                )}
                                {(isExact || isApproximate) && !td.inStock && (
                                  <div>{I18n.t('react.shop.payment.in_stock')}</div>
                                )}
                              </div>
                            )}
                          </div>
                        )
                      })}
                    </>
                  ) : (
                    <>
                      {ticket?.ticketDates?.map((td) => {
                        const withDates = td?.eventForm === 'with_dates'
                        const selectedTicket = ifTicketDate(paymentStore.store?.event?.tickets, ticket?.id, td?.id)

                        if (td?.limit === 0) {
                          return null
                        }

                        const enabledCheckboxStyle =
                          !!selectedTicket && color
                            ? {
                                backgroundColor: color,
                                borderColor: color,
                              }
                            : {}

                        return (
                          <div className={`ticket-metadata ${selectedTicket ? 'selected' : ''}`} key={td?.id}>
                            <div className='ticket-data'>
                              <div>
                                <CheckboxField
                                  onChange={(e) => paymentStore.handleTicketDateChange(e, ticket?.id, td?.id)}
                                  checked={!!selectedTicket}
                                  label={withDates ? getFormattedTicketDates(td) : td?.additionalText}
                                  disabled={!!paymentStore.invoice?.token}
                                  style={enabledCheckboxStyle}
                                  labelClasses='checkbox-field__label--without-margin'
                                  className='field--no-margin'
                                  noMargin
                                />
                                {withDates && <div className='ticket-data__additional-text'>{td?.additionalText}</div>}
                                {td?.limit && (
                                  <span className='limit-help'>
                                    {I18n.t('react.shop.payment.form.ticket.max')}: {td.limit}
                                  </span>
                                )}
                                {td?.minToBeBought && td.minToBeBought !== 1 ? (
                                  <span className='limit-help'>
                                    {I18n.t('react.shop.payment.form.ticket.min')}: {td.minToBeBought}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>

                              <div className='ticket-count'>
                                {td?.limit && (
                                  <span className='limit-help'>
                                    {I18n.t('react.shop.payment.form.ticket.max')}: {td.limit}
                                  </span>
                                )}
                                {td?.minToBeBought && td.minToBeBought !== 1 ? (
                                  <span className='limit-help'>
                                    {I18n.t('react.shop.payment.form.ticket.min')}: {td.minToBeBought}
                                  </span>
                                ) : (
                                  ''
                                )}
                                <ButtonInputGroupPreview
                                  disabled={!selectedTicket}
                                  activateOnClick
                                  min={td?.minToBeBought}
                                  max={td?.limit}
                                  value={selectedTicket?.count || 0}
                                  data-ticket-id={ticket?.id}
                                  data-ticket-date-id={td?.id}
                                  onChange={(count) =>
                                    paymentStore.handleTicketsCountChange(ticket?.id, td?.id, count, !!selectedTicket)
                                  }
                                  style={enabledStyle}
                                  key={selectedTicket?.count}
                                />
                              </div>
                            </div>

                            {withDates && (
                              <div className='ticket-data__additional-text--tablet'>{td?.additionalText}</div>
                            )}
                            {showLimit && (
                              <div className='stock-count'>
                                {((isExact && td.inStock) || (td.inStock && td.inStock <= 10)) && (
                                  <div>{I18n.t('react.shop.payment.exact_limit', { limitForSale: td.inStock })}</div>
                                )}
                                {isApproximate && td.inStock > 10 && (
                                  <div>{I18n.t('react.shop.payment.approximate_limit')}</div>
                                )}
                                {(isExact || isApproximate) && !td.inStock && (
                                  <div>{I18n.t('react.shop.payment.in_stock')}</div>
                                )}
                              </div>
                            )}
                          </div>
                        )
                      })}
                    </>
                  )}
                </div>
                {showPlans && !twoStepsAdvanced && !hideInvoicePricingPlans && (
                  <>
                    <div className='product-content-divider' />
                    <div className='pricing-content'>
                      <PricingPlansList
                        keepEnabled
                        pricingPlans={plansToShow}
                        selectedId={(paymentStore.store?.event?.tickets?.[ticket?.id] || {}).pricingPlanId}
                        forForm={paymentStore.store?.event?.tickets?.[ticket?.id]}
                        coupon={paymentStore.store?.coupon}
                        ticketId={ticket?.id}
                        onSelectPlan={(planId) => paymentStore.handleSelectPlan(ticket?.id, planId)}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className='cut-out-left' />
              <div className='cut-out-right' />
            </div>
            {twoStepsAdvanced && !hideInvoicePricingPlans && (
              <div className='col-lg-4 brl-lg pricing-content pricing-content--with-bottom-margin'>
                <PricingPlansList
                  keepEnabled
                  hideTitle
                  pricingPlans={plansToShow}
                  selectedId={(paymentStore.store?.event?.tickets?.[ticket?.id] || {}).pricingPlanId}
                  forForm={paymentStore.store?.event?.tickets?.[ticket?.id]}
                  coupon={paymentStore.store?.coupon}
                  ticketId={ticket?.id}
                  onSelectPlan={(planId) => paymentStore.handleSelectPlan(ticket?.id, planId)}
                />
              </div>
            )}
          </Fragment>
        )
      })}
    </div>
  )
})
