import React from 'react'

import './payer-data.scss'

interface Props {
  payerData: {
    label: string
    value: string
  }[]
}

export const PayerData: React.FC<Props> = ({ payerData }) => (
  <div className='payer-data'>
    {payerData.map(({ label, value }, index) => (
      <div key={`${label}-${index}`} className='payer-data__container'>
        <div className='payer-data--label'>{label}</div>
        <div className='payer-data--text'>{value}</div>
      </div>
    ))}
  </div>
)
