import React, { Fragment } from 'react'
import { observer } from 'mobx-react'

import { FormattedTicketDates } from '@elo-kit/components/formatters/formatted-ticket-dates/FormattedTicketDates'
import { CheckboxField } from '@elo-kit/components/form/checkbox-field/CheckboxField'
import { SelectField } from '@elo-kit/components/form/select-field/SelectField'

import { useShopStores } from 'shop/hooks/use-store'
import { getFormattedTicketLocation } from 'utils/tickets.utils'
import { convertFieldsObject } from 'utils/payerForm.utils'
import { PayerFormsFields } from './payerFormsFields'

export const Attendees: React.FC = observer(function Attendees() {
  const { paymentStore, sellerStore, themeStore, countriesStore } = useShopStores()
  const { ...fields } = themeStore.ppTemplate.buyerInformation

  const getTicketByTicketDateId = (ticketDateId: number) => {
    const ticketItemInUpsell = paymentStore.store?.upsell?.find(
      (upsellItem) => Number(upsellItem.ticketDateId) === Number(ticketDateId)
    )

    if (ticketItemInUpsell) {
      return ticketItemInUpsell.ticket
    }

    const ticketItemInAddon = paymentStore.store?.upsell?.map((upsellItem) => {
      if (upsellItem && upsellItem.addons) {
        const { addons } = upsellItem
        for (let i = 0; i < addons.length; i++) {
          const addon = addons[i]
          const { ticket, ticketDate } = addon
          if (ticketDate && String(ticketDate.id) === String(ticketDateId)) {
            return ticket
          }
        }
      }
    })

    if (ticketItemInAddon?.length > 0 && ticketItemInAddon[0]) {
      return ticketItemInAddon[0]
    }

    return paymentStore.product?.tickets?.find((ticket) =>
      ticket.ticketDates.find(({ id }) => String(id) === String(ticketDateId))
    )
  }

  const getTicketDate = (ticketDateId: number) => {
    const ticketItemInUpsell = paymentStore.store?.upsell?.find(
      (upsellItem) => Number(upsellItem.ticketDateId) === Number(ticketDateId)
    )

    if (ticketItemInUpsell) {
      return ticketItemInUpsell.ticketDate
    }

    const ticketItemInAddon = paymentStore.store?.upsell?.map((upsellItem) => {
      if (upsellItem && upsellItem.addons) {
        for (let i = 0; i < upsellItem.addons.length; i++) {
          const addon = upsellItem.addons[i]
          if (addon.ticketDate && String(addon.ticketDate.id) === String(ticketDateId)) {
            return addon.ticketDate
          }
        }
      }
    })

    if (ticketItemInAddon?.length > 0 && ticketItemInAddon[0]) {
      return ticketItemInAddon[0]
    }

    const ticket = getTicketByTicketDateId(ticketDateId)
    return ticket && ticket.ticketDates.find((td) => String(td.id) === String(ticketDateId))
  }

  const attendeeFieldOptions = (i: number) => {
    const options = [
      {
        value: 'default',
        label: I18n.t('react.shop.payment.form.select_optional'),
      },
      {
        value: 'payer',
        label: I18n.t('react.shop.payment.form.payer'),
      },
    ]
    Object.keys(paymentStore.store?.attendees?.list).forEach((key, index) => {
      if (index < i) {
        options.push({
          value: key,
          label: `${I18n.t('react.shop.payment.form.ticket_select')} ${index + 1}`,
        })
      }
    })
    return options
  }

  const color = themeStore.ppTemplate?.theme.paymentPageColor
  const enabledCheckboxStyle = color
    ? {
        backgroundColor: color,
        borderColor: color,
      }
    : {}

  return (
    <div className='attendees'>
      <div className='container-title'>{I18n.t('react.shop.payment.form.attendees')}</div>
      <CheckboxField
        checked={!paymentStore.store?.attendees?.active}
        name='buyerAttendee'
        label={I18n.t('react.shop.payment.form.buyer_attendees')}
        onChange={paymentStore.handleAttendeesChangeChk}
        style={!paymentStore.store?.attendees?.active ? enabledCheckboxStyle : {}}
      />
      <CheckboxField
        checked={paymentStore.store?.attendees?.attendeeEmail}
        name='attendeeEmail'
        label={I18n.t('react.shop.payment.form.send_attendees_email')}
        onChange={paymentStore.handleAttendeesSendEmailChk}
        style={paymentStore.store?.attendees?.attendeeEmail ? enabledCheckboxStyle : {}}
      />
      <div className='details'>{I18n.t('react.shop.payment.form.agree_receive_email')}</div>
      {/* eslint-disable-next-line */}
      {paymentStore.store?.attendees?.active ? (
        Object.keys(paymentStore.store?.attendees?.list).length === 0 ? (
          <div className='attendees-empty'>{I18n.t('react.shop.payment.no_tickets_selected')}</div>
        ) : (
          <div className='attendees-list'>
            {Object.keys(paymentStore.store?.attendees?.list).map((key, index) => {
              const attendee = paymentStore.store?.attendees?.list[key]
              const ticket = getTicketByTicketDateId(attendee.ticketDateId)
              const ticketDate = getTicketDate(attendee.ticketDateId)
              if (!ticket || !ticketDate) {
                return null
              }
              const withDates = ticketDate.eventForm === 'with_dates'
              return (
                <div className='attendee-form' key={key}>
                  <div className='row '>
                    <div className='col-12'>
                      <div className='attendee-ticket'>
                        {I18n.t('react.shop.payment.form.ticket_name')} #{index + 1}
                      </div>
                      <div className='attendee-ticket-name'>{ticket.name}</div>
                      <div className='attendee-ticket-info'>
                        {withDates ? (
                          <Fragment>
                            <div>
                              <i className='fas fa-map-marker-alt' />
                              {withDates
                                ? getFormattedTicketLocation(ticket, countriesStore.list)
                                : ticket.additionalText}
                            </div>
                            <div>
                              <i className='fas fa-clock' />
                              <FormattedTicketDates ticketDate={ticketDate} withAdditionalText />
                            </div>
                            {ticketDate.additionalText && (
                              <div>
                                <i className='fas fa-file-alt' />
                                <span className='attendee-ticket-info__additional-text'>
                                  {ticketDate.additionalText}
                                </span>
                              </div>
                            )}
                          </Fragment>
                        ) : (
                          <span className='attendee-ticket-info__additional-text'>{ticketDate.additionalText}</span>
                        )}
                      </div>
                    </div>
                    <div className='col-12 attendee-fields'>
                      <label className='attendee-label'>{I18n.t('react.shop.payment.form.copy_data_from')}</label>
                      <div className='select country-select form-group'>
                        <SelectField
                          onChange={(value) => {
                            paymentStore.handleSelectCopyChange(key, value)
                          }}
                          defaultValue='default'
                          options={attendeeFieldOptions(index)}
                        />
                      </div>
                      <PayerFormsFields
                        fullCountriesList
                        sellerCountryCode={sellerStore.item.vatCountryCode}
                        data={paymentStore.store?.attendees?.list[key]}
                        config={convertFieldsObject(fields.attendee)}
                        forceDirty={paymentStore.store.validateOnSubmit}
                        handleInput={(inputName, value) => {
                          paymentStore.updateAttendees(key, inputName, value)
                        }}
                        handleValid={(inputName, value) => {
                          paymentStore.updateAttendeesValid(key, inputName, value)
                        }}
                        withDates={withDates}
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )
      ) : null}
    </div>
  )
})
