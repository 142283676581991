import React, { Fragment, useState } from 'react'
import { observer } from 'mobx-react'

import { EloStatusBadge } from '@elo-ui/components/elo-status-badge'
import { EloCheckCircleIcon } from '@elo-ui/components/icons/regular'
import { EloPriceDisplay } from '@elo-ui/components/elo-price-display'
import { EloLink } from '@elo-ui/components/elo-link'
import { EloProductDisplay } from '@elo-ui/components/elo-product-display'
import { EloModal, EloModalBody, EloModalFooter, EloModalHeader } from '@elo-ui/components/elo-modal'
import { EloButton } from '@elo-ui/components/elo-button'

import { convertToPercents } from 'utils/helpersShared.utils'
import { getFirstCoverUrl } from 'utils/product.utils'

import { COUPON_VALUE_FORM } from 'constants/coupons.constants'
import { PAYMENT_PLANS } from 'constants/pricingPlans.constants'

import { useShopStores } from 'shop/hooks/use-store'

import './new-summary-prices.scss'

export const NewSummaryPrices = observer(() => {
  const [showDetailsModal, setShowDetailsModal] = useState(false)

  const { currenciesStore, paymentStore } = useShopStores()
  const { newCheckoutSummary } = paymentStore

  const sellablesCountWithCoupon = newCheckoutSummary.sellables.filter(
    (sellable) => sellable.firstPaymentDiscount !== 0
  ).length
  const isCouponApplied = !!newCheckoutSummary.coupon.value
  const isCouponInPercents = newCheckoutSummary.coupon.valueForm === COUPON_VALUE_FORM.percents
  const subscriptionSellable = newCheckoutSummary.sellables.filter(
    (sellable) => sellable.pricingPlanForm === PAYMENT_PLANS.subscription
  )
  const installmentOrLimSubscriptionSellable = newCheckoutSummary.sellables.filter(
    (sellable) =>
      sellable.pricingPlanForm === PAYMENT_PLANS.installment ||
      sellable.pricingPlanForm === PAYMENT_PLANS.limitedSubscription
  )
  const isOneTimeSellable = newCheckoutSummary.sellables.every(
    (sellable) => sellable.pricingPlanForm === PAYMENT_PLANS.oneTime
  )

  const getFormattedPrice = (amount: number) => currenciesStore.convertToPrice(amount, newCheckoutSummary.currencyId)

  const productCouponLabel = I18n.t('react.shop.payment.product', { count: sellablesCountWithCoupon })

  const shortSummary = (
    <>
      <div className='new-summary-prices__total-today'>
        <div className='new-summary-prices__total-today--label'>
          {isOneTimeSellable ? I18n.t('react.shop.payment.total') : I18n.t('react.shop.payment.due_today')}
        </div>
        <EloPriceDisplay
          price={getFormattedPrice(newCheckoutSummary.firstPaymentsSum)}
          oldPrice={
            isCouponApplied
              ? getFormattedPrice(newCheckoutSummary.firstPaymentsSum + newCheckoutSummary.firstPaymentsDiscountsSum)
              : ''
          }
        />
      </div>
      {subscriptionSellable.length === 1 && (
        <div className='new-summary-prices__total'>
          <div className='new-summary-prices__total--label'>
            {I18n.t('react.cabinet.common.from').toLowerCase()} {subscriptionSellable[0].nextChargeDate}
          </div>
          <EloPriceDisplay
            withoutPriceWrap
            price={getFormattedPrice(newCheckoutSummary.nextPaymentsSum)}
            oldPrice={
              !!newCheckoutSummary.nextPaymentsDiscountsSum
                ? getFormattedPrice(newCheckoutSummary.nextPaymentsSum + newCheckoutSummary.nextPaymentsDiscountsSum)
                : ''
            }
            size='extra-small'
            supportiveText={`/${subscriptionSellable[0].recurringInterval.rawDuration}`}
          />
        </div>
      )}
      {!!installmentOrLimSubscriptionSellable.length && (
        <div className='new-summary-prices__total'>
          <div className='new-summary-prices__total--label'>{I18n.t('react.shop.payment.total')}</div>
          <EloPriceDisplay
            price={getFormattedPrice(newCheckoutSummary.allPaymentsSum)}
            oldPrice={
              isCouponApplied
                ? getFormattedPrice(newCheckoutSummary.allPaymentsSum + newCheckoutSummary.allPaymentsDiscountsSum)
                : ''
            }
            size='extra-small'
          />
        </div>
      )}
    </>
  )

  return (
    <div className='new-summary-prices'>
      {isCouponApplied && (
        <div className='new-summary-prices__coupon'>
          <EloStatusBadge
            highlight={false}
            badgeColor='var(--color-highlight-web)'
            icon={<EloCheckCircleIcon />}
            className='new-summary-prices__coupon--applied'
          >
            <span>
              {`${
                isCouponInPercents
                  ? convertToPercents(Number(newCheckoutSummary.coupon.value))
                  : currenciesStore.convertToPrice(newCheckoutSummary.coupon.value, newCheckoutSummary.currencyId)
              } ${I18n.t('react.shared.off')}`}
            </span>
          </EloStatusBadge>
          <span className='new-summary-prices__coupon--label'>{productCouponLabel}</span>
        </div>
      )}
      <div className='new-summary-prices__prices-info'>{shortSummary}</div>
      <div className='new-summary-prices__details-link'>
        <EloLink as='span' onClick={() => setShowDetailsModal(true)}>
          {I18n.t('react.shared.view_details')}
        </EloLink>
      </div>

      {showDetailsModal && (
        <EloModal
          isOpen
          onClose={() => setShowDetailsModal(false)}
          className='new-summary-prices-modal'
          dataTestId='details-modal'
        >
          <EloModalHeader>{I18n.t('react.shop.payment.summary_modal.title')}</EloModalHeader>
          <EloModalBody>
            <div>
              {newCheckoutSummary.sellables.map((sellable, index) => {
                const {
                  productName,
                  pricingPlanForm,
                  firstPayment,
                  firstPaymentDiscount,
                  nextPayment,
                  nextPaymentDiscount,
                  allPayments,
                  allPaymentsDiscount,
                  firstPaymentVat,
                  firstPaymentVatDiscount,
                  allPaymentsVat,
                  allPaymentsVatDiscount,
                  totalFees,
                  totalFeesDiscount,
                  vatPercentage,
                  paymentsCount,
                  productForm,
                  nextChargeDate,
                  recurringInterval,
                  priceCount,
                } = sellable

                const isOneTime = pricingPlanForm === PAYMENT_PLANS.oneTime
                const isSubscription = pricingPlanForm === PAYMENT_PLANS.subscription
                const isInstallmentOrLimitedSubscription =
                  pricingPlanForm === PAYMENT_PLANS.installment || pricingPlanForm === PAYMENT_PLANS.limitedSubscription

                return (
                  <Fragment key={index}>
                    {!!firstPaymentDiscount && (
                      <div className='new-summary-prices__coupon'>
                        <EloStatusBadge
                          highlight={false}
                          badgeColor='var(--color-highlight-web)'
                          icon={<EloCheckCircleIcon />}
                          className='new-summary-prices__coupon--applied'
                        >
                          <span>
                            {`${
                              isCouponInPercents
                                ? convertToPercents(Number(newCheckoutSummary.coupon.value))
                                : currenciesStore.convertToPrice(
                                    newCheckoutSummary.coupon.value,
                                    newCheckoutSummary.currencyId
                                  )
                            } ${I18n.t('react.shared.off')}`}
                          </span>
                        </EloStatusBadge>
                      </div>
                    )}
                    <div className='new-summary-prices-modal__product'>
                      {priceCount > 1 && <div>{priceCount}x</div>}
                      <EloProductDisplay name={productName} image={getFirstCoverUrl(sellable)} type={productForm} />
                      <EloPriceDisplay
                        withoutPriceWrap
                        price={getFormattedPrice(firstPayment)}
                        oldPrice={!!firstPaymentDiscount ? getFormattedPrice(firstPayment + firstPaymentDiscount) : ''}
                        size='small'
                      />
                    </div>
                    {isInstallmentOrLimitedSubscription && (
                      <div className='new-summary-prices-modal__total-payments'>
                        <div className='new-summary-prices-modal__total-payments--label'>
                          {I18n.t('react.shop.payment.total_payments', { count: paymentsCount })}
                        </div>
                        <EloPriceDisplay
                          price={getFormattedPrice(allPayments)}
                          oldPrice={!!allPaymentsDiscount ? getFormattedPrice(allPayments + allPaymentsDiscount) : ''}
                          size='extra-small'
                        />
                      </div>
                    )}
                    {!!vatPercentage && (
                      <div className='new-summary-prices-modal__vat'>
                        <div className='new-summary-prices-modal__vat--label'>
                          {I18n.t('react.shop.payment.vat_amount')} {vatPercentage}%
                        </div>
                        {isOneTime || isSubscription ? (
                          <EloPriceDisplay
                            withPlusSymbol
                            withoutPriceWrap
                            price={getFormattedPrice(firstPaymentVat)}
                            oldPrice={
                              !!firstPaymentVatDiscount
                                ? getFormattedPrice(firstPaymentVat + firstPaymentVatDiscount)
                                : ''
                            }
                            size='extra-small'
                          />
                        ) : (
                          <div className='new-summary-prices-modal__vat--price'>
                            <EloPriceDisplay
                              withPlusSymbol
                              price={getFormattedPrice(firstPaymentVat)}
                              oldPrice={
                                !!firstPaymentVatDiscount
                                  ? getFormattedPrice(firstPaymentVat + firstPaymentVatDiscount)
                                  : ''
                              }
                              size='extra-small'
                              supportiveText={I18n.t('react.shared.filter.today')?.toLowerCase()}
                            />
                            <div className='new-summary-prices-modal__vat--total-price'>
                              (
                              <EloPriceDisplay
                                withPlusSymbol
                                price={getFormattedPrice(allPaymentsVat)}
                                oldPrice={
                                  !!allPaymentsVatDiscount
                                    ? getFormattedPrice(allPaymentsVat + allPaymentsVatDiscount)
                                    : ''
                                }
                                size='extra-small'
                                supportiveText={I18n.t('react.shop.payment.total')?.toLowerCase()}
                              />
                              )
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {subscriptionSellable.length > 1 && (
                      <div className='new-summary-prices-modal__from'>
                        <div className='new-summary-prices-modal__from--label'>
                          {I18n.t('react.cabinet.common.from').toLowerCase()} {nextChargeDate}
                        </div>
                        <EloPriceDisplay
                          withoutPriceWrap
                          price={getFormattedPrice(nextPayment)}
                          oldPrice={!!nextPaymentDiscount ? getFormattedPrice(nextPayment + nextPaymentDiscount) : ''}
                          size='extra-small'
                          supportiveText={`/${recurringInterval.rawDuration}`}
                        />
                      </div>
                    )}
                    {(!!totalFees || (totalFees === 0 && !!totalFeesDiscount)) && (
                      <div className='new-summary-prices-modal__fee'>
                        <div className='new-summary-prices-modal__fee--label'>
                          {I18n.t('react.shop.payment.fee_amount')}
                        </div>
                        <EloPriceDisplay
                          withPlusSymbol
                          price={getFormattedPrice(totalFees)}
                          oldPrice={!!totalFeesDiscount ? getFormattedPrice(totalFees + totalFeesDiscount) : ''}
                          size='extra-small'
                        />
                      </div>
                    )}
                    <div className='new-summary-prices-modal__separator' />
                  </Fragment>
                )
              })}
            </div>
            <div>{shortSummary}</div>
          </EloModalBody>
          <EloModalFooter className='elo-ui-modal__footer--center'>
            <EloButton onClick={() => setShowDetailsModal(false)} variant='secondary'>
              {I18n.t('react.shared.button.close')}
            </EloButton>
          </EloModalFooter>
        </EloModal>
      )}
    </div>
  )
})
