import React, { FC } from 'react'
import Select from 'react-select'
import classNames from 'classnames'

import { AnyCallback } from 'types/helpers'

import { DropdownIndicator } from './dropdown-indicator/DropdownIndicator'

import './multiple_ticket_date_selector.scss'

interface Components {
  DropdownIndicator?: React.FC
}

interface Option {
  label: string
  value: string
}

interface Value {
  label?: string
  value?: any
}

interface Props {
  className?: string
  classNamePrefix?: string
  containerClassName?: string
  dataTestId?: string
  name?: string
  menuPlacement?: string
  noOptionsMessage?: string
  onChange: AnyCallback
  options?: Option[]
  searchable?: boolean
  value?: string | Value
  placeholder?: string
  components?: Components
}

const defaultProps: Partial<Props> = {
  classNamePrefix: 'multiple-ticket-date-field',
  dataTestId: 'multiple-ticket-date-field',
  menuPlacement: 'auto',
  options: [],
  searchable: false,
  components: {
    DropdownIndicator: DropdownIndicator,
  },
}

export const MultipleTicketDateSelector: FC<Props> = (props) => {
  const {
    className,
    classNamePrefix,
    containerClassName,
    dataTestId,
    menuPlacement,
    noOptionsMessage,
    options,
    searchable,
    value,
    placeholder,
    onChange,
    components,
  } = props

  const onFieldChange = (option: any) => {
    onChange(option?.value)
  }

  let selectValue = value

  if (typeof selectValue === 'string' || typeof selectValue === 'number') {
    selectValue = options?.find((option) => String(option.value) === String(selectValue))
  }

  const fieldContainerClassNames = classNames('field multiple-ticket-date-container', containerClassName)
  const fieldClassNames = classNames('multiple-ticket-date-field', className)

  const lastOption = options.length === 1

  return (
    <div className={fieldContainerClassNames} data-testid={dataTestId}>
      <Select
        instanceId={`${dataTestId || 'name'}-react-select`}
        className={fieldClassNames}
        isSearchable={searchable}
        onChange={onFieldChange}
        value={selectValue || ''}
        noOptionsMessage={() => noOptionsMessage}
        closeMenuOnSelect={lastOption}
        {...{
          components,
          classNamePrefix,
          menuPlacement,
          options,
          placeholder,
        }}
      />
    </div>
  )
}

MultipleTicketDateSelector.displayName = 'MultipleTicketDateSelector'
MultipleTicketDateSelector.defaultProps = defaultProps
