import React, { useState } from 'react'
import classNames from 'classnames'

import { EloCaretUpIcon, EloCaretDownIcon } from '@elo-ui/components/icons/regular'

import './elo-accordion.scss'

type Size = 'small' | 'medium' | 'large'

interface Item {
  headline: React.ReactNode
  content: React.ReactNode
  isDisabled?: boolean
  isOpened?: boolean
  thumbnailLink?: string
  size?: Size
  shortDescription?: boolean
}

interface Props {
  items: Item[]
  size?: Size
  onItemToggle?: (item: Item, isOpen: boolean) => void
  elevation?: '1' | '2'
  shortDescription?: boolean
}

const EloAccordionItem: React.FC<Item & { onItemToggle?: (isOpened: boolean) => void }> = ({
  headline,
  content,
  isDisabled = false,
  isOpened = false,
  thumbnailLink,
  size,
  onItemToggle = () => {},
  shortDescription,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(isOpened ? isOpened : false)
  const toggleCollapse = () => {
    if (!isDisabled) {
      setIsCollapsed(!isCollapsed)
      onItemToggle(!isCollapsed)
    }
  }
  const contentClassNames = classNames('elo-accordion__content', {
    'elo-accordion__content--collapsed': isCollapsed,
    'elo-accordion__content--short-description': !isCollapsed && shortDescription,
  })
  const headerClassNames = classNames('elo-accordion__header', {
    'elo-accordion__header--disabled': isDisabled,
  })

  return (
    <div>
      <h4 className={headerClassNames} onClick={toggleCollapse}>
        {thumbnailLink && (
          <img
            className={classNames('elo-accordion__image', `elo-accordion__image--${size}`)}
            src={thumbnailLink}
            alt='thumbnail'
          />
        )}
        {headline}
        <span className='elo-accordion__caret_icon'>{isCollapsed ? <EloCaretUpIcon /> : <EloCaretDownIcon />}</span>
      </h4>
      <div className={contentClassNames}>
        <div>{content}</div>
      </div>
    </div>
  )
}

export const EloAccordion: React.FC<Props> = ({
  size = 'large',
  items = [],
  elevation = '1',
  onItemToggle = () => {},
  shortDescription = false,
}) => (
  <div className={classNames('elo-accordion', `elo-accordion__${size}`, `elo-accordion--elevation-${elevation}`)}>
    {items.map(({ headline, content, isDisabled, isOpened, thumbnailLink }, idx) => (
      <EloAccordionItem
        key={idx}
        size={size}
        headline={headline}
        content={content}
        isDisabled={isDisabled}
        isOpened={isOpened}
        thumbnailLink={thumbnailLink}
        shortDescription={shortDescription}
        onItemToggle={(isOpened) => onItemToggle(items[idx], isOpened)}
      />
    ))}
  </div>
)
