import React, { useState, useEffect } from 'react'

import { Radio } from 'ui-old/Radio'
import Input from 'ui-old/InputNext'
import { SelectField } from '@elo-kit/components/form/select-field/SelectField'

import { sortByPosition } from '@elo-kit/utils/helpers.utils'

import { CUSTOM_TEMPLATE_TYPES } from 'constants/optIns.constants'

interface Props {
  formData: {
    [key: number]: Record<string, never>
  }
  onInputChange: (id: number, value: string | boolean) => void
  questions: {
    mandatory: boolean
    id: number
    fieldType: string
    label: string
    optInOptions?: {
      id: number
      label: string
      createdAt: string
      optInQuestionId: number
      position: number
      updatedAt: string
    }[]
  }[]
  forceDirty: boolean
  paymentPageColor: string
  customCss?: string
}

export const OptInTerms: React.FC<Props> = (props) => {
  const { formData, onInputChange, questions, forceDirty, paymentPageColor } = props

  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    if (forceDirty && !isDirty) {
      setIsDirty(true)
    }
  }, [forceDirty, isDirty])

  const getOptInField = (id) => {
    const {
      label,
      mandatory,
      fieldType,
      optInOptions = [],
    } = questions.filter((item) => String(item.id) === String(id))[0] || {}
    const value = formData[id] || ''
    const selectOptions = optInOptions.sort(sortByPosition).reduce(
      (prevOption, option) => [
        ...prevOption,
        {
          label: option.label,
          value: option.label,
        },
      ],
      []
    )

    switch (fieldType) {
      case CUSTOM_TEMPLATE_TYPES.openQuestion:
        return (
          <Input
            hideErrorMsg
            wrapClass='form-group'
            class='form-control input-lg'
            validations={[mandatory ? 'required' : '']}
            handleBlur={() => onInputChange(id, value.trim())}
            handleInput={(value) => onInputChange(id, value)}
            handleValid={() => {}}
            type='text'
            name='name'
            forceDirty={isDirty}
            {...{
              label,
              mandatory,
              value,
            }}
          />
        )
      case CUSTOM_TEMPLATE_TYPES.checkbox:
      case CUSTOM_TEMPLATE_TYPES.service:
        return (
          <Radio
            type='checkbox'
            checked={!!value}
            onClick={() => onInputChange(id, !value)}
            paymentPageColor={paymentPageColor}
            {...{
              label,
              mandatory,
            }}
          />
        )
      case CUSTOM_TEMPLATE_TYPES.selectBox:
        return (
          <div className='opt-in-terms__select-box'>
            <SelectField
              onChange={(value) => onInputChange(id, value)}
              placeholder={I18n.t('react.shared.opt_in.select_box.placeholder')}
              value={value}
              label={label}
              options={selectOptions}
              required={mandatory}
            />
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className='opt-in-section'>
      {questions.map(({ id }) => (
        <div key={id} className='opt-in-terms row'>
          <div className='col-12 col-md pr-1'>{getOptInField(id)}</div>
        </div>
      ))}
    </div>
  )
}
