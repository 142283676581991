import React, { Fragment, useState } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import { OldPrice } from '@elo-kit/components/elo-ui/old-price/OldPrice'
import { RadioField } from '@elo-kit/components/form/radio-field/RadioField'
import { CheckboxField } from '@elo-kit/components/form/checkbox-field/CheckboxField'
import ButtonInputGroupPreview from '@elo-kit/components/button-input-group/ButtonInputGroup'
import { Countdown } from '@elo-kit/components/pricing-plans-list/countdown/Countdown'
import { EloAccordion } from '@elo-ui/components/elo-accordion'

import { FREE_PRICE } from 'constants/general.constants'
import {
  UPSELLS_TYPES,
  PRODUCT_TYPE_IDS,
  ONE_LINE_CHECKOUT_PAGE_PRODUCT_NAME,
  MAX_LICENSE_CODES_COUNT,
} from 'constants/productsShared.constants'
import { PAYMENT_PLANS } from 'constants/pricingPlans.constants'
import { ORDER_BUMPS_VIEW_FIELD_KEY, ORDER_BUMPS_VIEW_TYPES_KEYS } from 'constants/orderBumpsShared.constants'
import { EXPANDABLE_PRODUCT_DEFAULT_BEHAVIOUR, PAYMENT_PAGE_LAYOUT } from 'constants/paymentPageTemplates.constants'
import { PAYMENT_FORMS } from 'constants/paymentSettingShared.constants'
import { ACTIVE_PROFILES } from 'constants/profile.constants'

import { isWindows } from 'utils/browsers.utils'
import { getFirstCoverUrl } from 'utils/product.utils'
import { escapeQuotesInString } from 'utils/nameStyle.utils'
import { getBackgroundImageCss } from 'utils/themesShared.utils'
import { profile } from 'utils/profileHelper.utils'
import { parseTextFromHtml } from '@elo-kit/utils/froala.utils'

import PricingPlansList from 'shared/components/pricing-plans/PricingPlansList'
import { cleanupHTMLStyleFonts } from 'utils/fonts.utils'

import { useShopStores } from 'shop/hooks/use-store'

interface Props {
  isTemplatePreview: boolean
  iframeData?: {
    shortDescription: string
    pricingPlans: {
      id: number
    }[]
    free: boolean
  }
}
const getDefaultProduct = () => ({
  name: I18n.t('react.shared.order_bumps.default_product_title'),
  shortDescription: I18n.t('react.shared.order_bumps.default_product_desc'),
  pricingPlans: [
    {
      form: 'one_time',
      prefs: {
        price: 0,
      },
      currencyKey: 'usd',
    },
  ],
})

export const ShopHeader: React.FC<Props> = observer(function ShopHeader(props) {
  const { isTemplatePreview, iframeData } = props
  const { paymentStore, currenciesStore, productUpsellsStore, productSettingsStore, themeStore } = useShopStores()

  const {
    paymentCountdownBgColor,
    paymentCountdownBorderColor,
    paymentCountdownTextColor,
    paymentCountdownTimeColor,
    countdownText,
    expandableDescriptionOnMobileEnabled,
    expandableDescriptionDefaultBehavior,
  } = themeStore.ppTemplate?.theme || {}
  const { showLimit, limitVisibilityType } = productSettingsStore.item
  const { limitForSale } = paymentStore.product

  const [descriptionCollapsed, setDescriptionCollapsed] = useState(
    expandableDescriptionDefaultBehavior === EXPANDABLE_PRODUCT_DEFAULT_BEHAVIOUR.expanded
  )

  const handleCheck = (e) => {
    if (e) {
      e.stopPropagation()
    }

    const productSettings = productSettingsStore.item.prefs || {}
    const hasAnyUpsells =
      paymentStore.store?.upsell?.length && paymentStore.store.upsell.some((upsellItem) => upsellItem.id)
    const isBump = productSettings.upsellType === UPSELLS_TYPES.bump

    if (hasAnyUpsells && !isBump) {
      paymentStore.updateUpsellData({}, -1, true)
    }

    paymentStore.selectMainProduct(true)
    paymentStore.recalculateOnChange()
  }

  const isShopProfile = profile.profileType === ACTIVE_PROFILES.shop

  const hideInvoicePricingPlans =
    paymentStore.invoice?.token && paymentStore.buildedOrder?.paymentForm === PAYMENT_FORMS.payLater

  const hidePlanSelection = paymentStore.isMainProductSelected && paymentStore.showGroupedPricingPlans

  const headerProduct = isTemplatePreview ? getDefaultProduct() : paymentStore.product

  const isEvent = paymentStore.product?.form === PRODUCT_TYPE_IDS.eventTickets
  const selected = !paymentStore.store?.upsell?.[0]?.id
  const plansToShow = paymentStore.getProductPlansToShow(null, null, null, iframeData?.pricingPlans)
  const productSettings = productSettingsStore.item.prefs || {}
  const isBump = productSettings.upsellType === UPSELLS_TYPES.bump
  const isBundleActive = productSettings.upsell
  const isMainProductRequired = productSettings.mainProductRequired && isBump
  const selectable =
    !isEvent &&
    productUpsellsStore.list.length > 0 &&
    !paymentStore.invoice?.token &&
    (!isMainProductRequired || !isBump)

  const cover = escapeQuotesInString(getFirstCoverUrl(paymentStore.product, 's640') || '')

  const isFree = iframeData?.free || paymentStore.product?.free

  const oneTimePlan =
    !isFree && plansToShow.length === 1 && plansToShow[0].form === PAYMENT_PLANS.oneTime && plansToShow[0]

  const isOneTimeWithTestPeriod = oneTimePlan && plansToShow[0].testPeriodEnabled

  const isTwoStepsAdvanced = themeStore.ppTemplate?.layout === PAYMENT_PAGE_LAYOUT.twoStepsAdvanced
  const showPlans =
    !isEvent &&
    !isFree &&
    (!oneTimePlan || isOneTimeWithTestPeriod) &&
    plansToShow.length > 0 &&
    !hidePlanSelection &&
    !isTwoStepsAdvanced &&
    !hideInvoicePricingPlans

  const convertToPrice = (value) => currenciesStore.convertToPrice(value, oneTimePlan.currencyId)

  const productPrice = hidePlanSelection
    ? plansToShow.filter(({ id }) => String(id) === String(paymentStore.store?.product?.planId))[0]
    : oneTimePlan

  const { prefs = {} } = productPrice
  const headerPrefs = isTemplatePreview ? getDefaultProduct().pricingPlans[0].prefs : prefs

  const { price, rate1Amount, firstAmount, oldPrice } = headerPrefs

  const priceValue = convertToPrice(price || rate1Amount || firstAmount || 0)

  const firstPriceClassName = classNames('price', {
    'price-crossed': paymentStore.store?.coupon?.applied,
    'price-crossed--windows': paymentStore.store?.coupon?.applied && isWindows,
  })

  const containerClassNames = classNames('preview-content', {
    'preview-content-half-screen': showPlans,
    'preview-content-full-screen': !showPlans,
  })

  const checkboxFieldClassName = classNames({
    'checkbox-field--header-name':
      headerProduct.name.length > ONE_LINE_CHECKOUT_PAGE_PRODUCT_NAME && !isMainProductRequired,
  })

  const color = themeStore?.ppTemplate?.theme?.paymentPageColor
  const enabledCheckboxStyle =
    paymentStore.isMainProductSelected && color
      ? {
          backgroundColor: color,
          borderColor: color,
        }
      : {}

  const inputComponent =
    isBump && isBundleActive ? (
      <CheckboxField
        id={!!selected}
        checked={paymentStore.isMainProductSelected}
        label={headerProduct.name}
        onChange={handleCheck}
        className={checkboxFieldClassName}
        style={enabledCheckboxStyle}
      />
    ) : (
      <RadioField
        value
        id={paymentStore.isMainProductSelected}
        label={headerProduct.name}
        onChange={handleCheck}
        style={enabledCheckboxStyle}
      />
    )

  const mergeWithUpsellSection =
    productUpsellsStore.list
      ?.map((item) => item?.addonTheme?.prefs[ORDER_BUMPS_VIEW_FIELD_KEY] === ORDER_BUMPS_VIEW_TYPES_KEYS.seven)
      .every(Boolean) &&
    paymentStore.isMainProductSelected &&
    !!paymentStore.showGroupedPricingPlans

  const collapsibleHeaderClasses = classNames(`header ${paymentStore.product?.form} collapsible-header`, {
    free: isFree,
    selected: selectable && paymentStore.isMainProductSelected && !mergeWithUpsellSection,
    merged: mergeWithUpsellSection,
  })

  const headerClasses = classNames(`header ${paymentStore.product?.form}`, {
    free: isFree,
    selected: selectable && paymentStore.isMainProductSelected && !mergeWithUpsellSection,
    merged: mergeWithUpsellSection,
    'header--hidden': expandableDescriptionOnMobileEnabled,
  })

  const showHeaderPrice =
    !hideInvoicePricingPlans && ((oneTimePlan && !isOneTimeWithTestPeriod) || !!hidePlanSelection || isTemplatePreview)

  const [isExact, isApproximate] = ['exact', 'approximate'].map((item) => item === limitVisibilityType)

  const headerProductDescription = (
    <>
      <div className='preview-content--in-row'>
        <h1 className='header-name'>{selectable ? inputComponent : headerProduct.name}</h1>
        {isFree && (
          <div className='header-price'>
            <span className='price'>{FREE_PRICE}</span>
          </div>
        )}
        {showHeaderPrice && (
          <div className='header-price'>
            <span className={firstPriceClassName} translate='no'>
              {priceValue} {productPrice?.useNetPrice && <i>{I18n.t('react.shared.netto')}</i>}
            </span>
            {parseFloat(oldPrice || 0) > 0 && (
              <OldPrice isWindows={isWindows}>{convertToPrice(parseFloat(oldPrice || 0))}</OldPrice>
            )}
            {paymentStore.store?.coupon?.applied && (
              <Fragment>
                <div className='clearfix' />
                <div className='coupon-applied' data-testid='coupon-applied'>
                  <i className='fas fa-check' />
                  {I18n.t('react.shared.coupon.applied')}
                </div>
              </Fragment>
            )}
          </div>
        )}
      </div>

      <div className='preview-content__flex-container'>
        {isShopProfile && plansToShow[0]?.activateCountdown && showHeaderPrice && (
          <Countdown
            paymentCountdownBgColor={paymentCountdownBgColor}
            paymentCountdownBorderColor={paymentCountdownBorderColor}
            paymentCountdownTextColor={paymentCountdownTextColor}
            paymentCountdownTimeColor={paymentCountdownTimeColor}
            countdownText={countdownText}
            validTill={plansToShow[0].validTill}
            key={plansToShow[0].validTill}
          />
        )}
        <div className='row'>
          {cover && paymentStore.product?.showCover && (
            <div className='col-lg-auto'>
              <div className='header-image-wrap'>
                <div
                  className='header-image'
                  data-testid='header-image'
                  style={{ backgroundImage: getBackgroundImageCss(cover) }}
                />
              </div>
            </div>
          )}
          <div className='col'>
            {(iframeData?.shortDescription || headerProduct.shortDescription) && (
              <div
                className='header-description fr-view'
                dangerouslySetInnerHTML={{
                  __html: cleanupHTMLStyleFonts(iframeData?.shortDescription || headerProduct.shortDescription || ''),
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )

  return (
    <>
      {expandableDescriptionOnMobileEnabled && (
        <div className={collapsibleHeaderClasses}>
          <EloAccordion
            items={[
              {
                headline: headerProduct.name,
                content: descriptionCollapsed
                  ? headerProductDescription
                  : parseTextFromHtml(iframeData?.shortDescription || headerProduct.shortDescription || ''),
                isOpened: expandableDescriptionDefaultBehavior === EXPANDABLE_PRODUCT_DEFAULT_BEHAVIOUR.expanded,
                thumbnailLink: paymentStore.product?.showCover && cover,
              },
            ]}
            size='medium'
            shortDescription
            onItemToggle={(_, isOpen) => setDescriptionCollapsed(isOpen)}
          />
        </div>
      )}
      <div className={headerClasses}>
        <div className='header-product-wrap'>
          <div className='product-content'>
            <div className={containerClassNames}>
              <div
                className={classNames({
                  'header-content--default': expandableDescriptionOnMobileEnabled,
                })}
              >
                {headerProductDescription}
              </div>
              {showLimit && limitForSale && (
                <div className='stock-count' data-testid='stock-count'>
                  {(isExact || limitForSale <= 10) && (
                    <div>{I18n.t('react.shop.payment.exact_limit', { limitForSale })}</div>
                  )}
                  {isApproximate && limitForSale > 10 && <div>{I18n.t('react.shop.payment.approximate_limit')}</div>}
                </div>
              )}
            </div>
            {showPlans && <div className='product-content-divider' />}
            {showPlans && (
              <div className='pricing-content' data-testid='pricing-content'>
                <PricingPlansList
                  pricingPlans={plansToShow}
                  selectedId={paymentStore.store?.product?.planId}
                  forForm={!selectable || paymentStore.isMainProductSelected}
                  keepEnabled
                  coupon={paymentStore.store?.coupon}
                  onSelectPlan={paymentStore.handleProductSelectPlan}
                  hideTitle={paymentStore.invoice?.token}
                />
              </div>
            )}
          </div>
        </div>
        {paymentStore.isMainProductSelected && paymentStore.product?.sellMultiple && (
          <div className='header-count-wrap' data-testid='header-count-wrap'>
            <label className='header-count-label'>{I18n.t('react.shared.upsells.preview.count')}</label>
            <ButtonInputGroupPreview
              value={paymentStore.store?.product?.count}
              onChange={paymentStore.handleProductCount}
              min={1}
              max={paymentStore.product?.limitPerOrder || MAX_LICENSE_CODES_COUNT}
            />
            {paymentStore.product?.limitPerOrder && (
              <span className='limit-help' data-testid='limit-help'>
                {I18n.t('react.shop.payment.form.ticket.max')}: {paymentStore.product.limitPerOrder}
              </span>
            )}
          </div>
        )}
      </div>
    </>
  )
})
