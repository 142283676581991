import React, { Fragment, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import { convertFieldState, convertFieldsObject } from 'utils/payerForm.utils'
import { capitalize } from 'utils/nameStyle.utils'
import { getCountryName } from 'utils/countries.utils'

import { useShopStores } from 'shop/hooks/use-store'
import { RadioField } from '@elo-kit/components/form/radio-field/RadioField'
import { CheckboxField } from '@elo-kit/components/form/checkbox-field/CheckboxField'

import { PAYER_FORM_TYPE, PAYMENT_FORMS } from 'constants/paymentSettingShared.constants'

import { PayerFormsFields } from './payerFormsFields'
import { PayerData } from './payer-data/PayerData'

interface Props {
  isTemplatePreview?: boolean
}

export const PayerForms: React.FC<Props> = observer(function PayerForms(props) {
  const { isTemplatePreview } = props
  const [shouldRecalculatePrice, setShouldRecalculatePrice] = useState(true)
  const { paymentStore, themeStore, sellerStore, countriesStore, trackingUserEventsStore } = useShopStores()
  const { ...fields } = themeStore.ppTemplate.buyerInformation
  const { firstName, lastName, street, streetNumber, zip, city, country, phone, email } =
    paymentStore.payerForms.userData.payer

  const buyerIncludeType = themeStore.ppTemplate?.buyerInformation?.includeType
  const skipPrefill = paymentStore.isPayerFormPrefilled && !isTemplatePreview && !paymentStore.invoice?.token
  const total = ['one_time', 'installment'].includes(paymentStore.buildedOrder.periodType)
    ? paymentStore.orderRatesTotal
    : paymentStore.getOrderRateHigherThan(250)
  const { private250, private: privateForm, business } = PAYER_FORM_TYPE
  const isSummary250eur = total >= 250 && paymentStore.currency === 'eur'
  const PAYER_FORMS = isSummary250eur ? [private250, business] : [privateForm, business]
  const payerFormWrapLabels = {
    private: I18n.t('react.shop.payment.payer_form_wrap.private'),
    business: I18n.t('react.shop.payment.payer_form_wrap.business'),
  }

  if (paymentStore.payerFromType === privateForm && isSummary250eur) {
    paymentStore.handleFormTypeSelector(private250, true)
  }

  if (paymentStore.payerFromType === private250 && !isSummary250eur) {
    paymentStore.handleFormTypeSelector(privateForm, true)
  }

  useEffect(() => {
    setTimeout(() => {
      // TODO: SSR - refactor in scope of FLOW-11479
      if (!paymentStore.isPayerFormsFullyValid && !paymentStore.payerForms.skipPrefill) {
        paymentStore.openForm()
      }

      if (paymentStore.product.free && !paymentStore.isValidFor('payer')) {
        paymentStore.openForm()
      }
    }, 300)
  }, [])

  if (skipPrefill) {
    const countrySeparator = [zip, city].some(Boolean) ? ',' : ''

    const config = convertFieldsObject(themeStore.ppTemplate.buyerInformation[paymentStore.payerFromType] || {})

    const fieldsToValidate = Object.keys(config).filter(
      (field) => config[field] && config[field].visible && config[field].visible === 'on'
    )
    const isIncludedOnPage = (fieldName) => !!fieldsToValidate.includes(fieldName)

    const countryName = getCountryName(countriesStore.list, country.code)

    const isIncludedStreet = isIncludedOnPage('street') || isIncludedOnPage('streetNumber')
    const isIncludedCity = isIncludedOnPage('city') || isIncludedOnPage('zip')
    const isIncludedPhone = isIncludedOnPage('phone')

    return (
      <div className='prefilled-payer-forms' data-testid='prefilled-payer-forms'>
        <div className='container-title'>{I18n.t('react.shop.payment.form.payer_info')}</div>
        <div className='simplified-payer-wrap'>
          <div>
            <div className='payer-name'>{`${firstName} ${lastName}`}</div>
            {isIncludedStreet && <div className='payer-street'>{`${street} ${streetNumber}`}</div>}
            {isIncludedCity && (
              <div className='payer-country'>{`${zip} ${city}${countrySeparator} ${countryName}`}</div>
            )}
            {isIncludedPhone && <div className='payer-phone'>{`${phone}`}</div>}
          </div>
          <a
            className='edit-payer-link'
            data-testid='edit-payer-link'
            onClick={() => {
              paymentStore.openForm()
            }}
          >
            {capitalize(I18n.t('react.shared.button.change'))}
          </a>
        </div>
      </div>
    )
  }

  const payerTypeSwitcher =
    buyerIncludeType &&
    buyerIncludeType?.private === 'on' &&
    buyerIncludeType?.business === 'on' &&
    !paymentStore.store?.free

  const color = themeStore.ppTemplate?.theme.paymentPageColor
  const enabledStyle = color
    ? {
        backgroundColor: color,
        borderColor: color,
      }
    : {}

  const paymentPayerType = isTemplatePreview ? privateForm : paymentStore.payerFromType
  const isCountryOn =
    convertFieldState(fields[paymentPayerType]?.country).visible === 'on' ||
    convertFieldState(fields[paymentPayerType]?.phone).visible === 'on'
  const payerGiftClasses = classNames('row payer-gift-check', {
    'payer-gift-check--with-top-margin': isCountryOn,
  })
  const payerSwitcherValue = isTemplatePreview
    ? paymentStore.payerForms?.formType || privateForm
    : paymentStore.payerForms?.formType

  const payerData = [
    {
      label: I18n.t('react.shared.name'),
      value: `${firstName} ${lastName}`,
    },
    {
      label: I18n.t('react.shared.address'),
      value: `${street} ${streetNumber}, ${zip} ${city}, ${country.code}`,
    },
    {
      label: I18n.t('react.shared.email'),
      value: email,
    },
  ]

  return (
    <div className='payer-forms' data-testid='payer-forms'>
      {paymentStore.invoice?.token ? (
        <>
          <div className='container-title' data-testid='invoice-details'>
            {I18n.t('react.shared.details')?.toUpperCase()}
          </div>
          <PayerData payerData={payerData} />
        </>
      ) : (
        <>
          <div className='container-title' data-testid='payer-form-fields'>
            {I18n.t('react.shop.payment.form.payer_info')}
          </div>
          {payerTypeSwitcher && (
            <div className='payer-type-switcher' data-testid='payer-type-switcher'>
              {PAYER_FORMS.map((type) => (
                <Fragment key={type}>
                  <RadioField
                    id={type}
                    label={payerFormWrapLabels[type.replace('250', '')]}
                    onChange={() => {
                      trackingUserEventsStore.track({
                        pageType: 'shop_checkout',
                        eventType: 'payer_type_change',
                        withExperiment: true,
                        payload: { selectedPayerType: type },
                      })
                      paymentStore.handleFormTypeSelector(type)
                    }}
                    value={payerSwitcherValue}
                    style={enabledStyle}
                    disabled={
                      type === PAYER_FORM_TYPE.business && paymentStore.store?.paymethods?.form === PAYMENT_FORMS.klarna
                    }
                    data-testid={`payer-type-switcher-${type}`}
                  />
                  <div className='payer-type-switcher__separator' />
                </Fragment>
              ))}
            </div>
          )}
          <PayerFormsFields
            sellerCountryCode={sellerStore.item.vatCountryCode}
            data={paymentStore.payerForms?.userData?.payer}
            emailConfirmation={themeStore.ppTemplate?.buyerInformation?.emailConfirmation?.visible}
            config={convertFieldsObject(fields[paymentPayerType])}
            forceDirty={paymentStore.store?.validateOnSubmit}
            handleInput={(inputName, value) => paymentStore.updatePayerFormsInput('payer', inputName, value)}
            handleValid={(inputName, value) => {
              const isRecalculated = inputName === 'vatNo' && !!value ? shouldRecalculatePrice : false

              if (isRecalculated) {
                setShouldRecalculatePrice(false)
              }

              paymentStore.updatePayerFormsValid('payer', inputName, value, isRecalculated)
            }}
          />

          {buyerIncludeType?.gift === 'on' && paymentStore.product?.form !== 'event' && (
            <Fragment>
              <div className={payerGiftClasses}>
                <div className='col-auto mr-auto'>
                  <CheckboxField
                    testId='payer-form-gift-switch'
                    name='showGift'
                    checked={paymentStore.payerForms?.showGift}
                    label={I18n.t('react.shop.payment.form.send_as_gift')}
                    onChange={() => {
                      trackingUserEventsStore.track({
                        pageType: 'shop_checkout',
                        eventType: 'gift_mode_change',
                        withExperiment: true,
                        payload: { isGiftMode: !paymentStore.payerForms.showGift },
                      })
                      paymentStore.switchGiftMode()
                    }}
                    style={paymentStore.payerForms?.showGift ? enabledStyle : {}}
                  />
                </div>
              </div>

              {paymentStore.payerForms?.showGift && (
                <div className='payer-gift-form' data-testid='payer-form-gift'>
                  <div className='container-title'>{I18n.t('react.shop.payment.form.gift_receiver_info')}</div>
                  <PayerFormsFields
                    fullCountriesList
                    sellerCountryCode={sellerStore.item.vatCountryCode}
                    data={paymentStore.payerForms?.userData?.owner}
                    forceDirty={paymentStore.store?.validateOnSubmit}
                    config={convertFieldsObject(themeStore.ppTemplate?.buyerInformation?.gift)}
                    handleInput={(inputName, value) => paymentStore.updatePayerFormsInput('owner', inputName, value)}
                    handleValid={(inputName, value) => paymentStore.updatePayerFormsValid('owner', inputName, value)}
                  />
                  <div className='title'>{I18n.t('react.shop.payment.form.gift_receiver_message_title')}</div>
                  <textarea
                    className='form-control'
                    value={paymentStore.payerForms?.giftComment}
                    onChange={paymentStore.handleGiftCommentChange}
                  />
                </div>
              )}
            </Fragment>
          )}
        </>
      )}
    </div>
  )
})
