import React from 'react'

import { formatDateTime } from '@elo-kit/utils/dateTime.utils'

import { DATE_FORMATS } from '@elo-kit/constants/dateTime.constants'

import './invoice-banner.scss'

interface Props {
  order: {
    id?: number
    createdAt?: string
  }
  manageLink: string
}

export const InvoiceBanner: React.FC<Props> = ({ order, manageLink }) => (
  <div className='invoice-banner'>
    <div className='invoice-banner__title'>
      <div className='order-text'>{I18n.t('react.shared.light_checkout_page.payment_order').toUpperCase()}</div>
      <a className='order-id' href={manageLink}>
        {I18n.t('shared.common.id').toUpperCase()} {order?.id}
      </a>
      <div className='order-from'>
        {I18n.t('react.shared.from').toLowerCase()} {formatDateTime(order?.createdAt, DATE_FORMATS.DDMMYYYY)}
      </div>
    </div>
    <div className='invoice-banner__message'>{I18n.t('react.shared.light_checkout_page.invoice_banner_message')}</div>
  </div>
)
