import React, { useEffect, useState } from 'react'

import { getTimeLeftObject, getTwoCharactersTime } from 'utils/dateTime.utils'
import { LocalStorageService } from 'utils/local-storage.utils'

import { COLORS } from '@elo-kit/constants/general.constants'

import './session-timer.scss'

const Time = ({ time }) => <span>{getTwoCharactersTime(time)}</span>

const MIN_TIMER_TIME = '00:05'

interface SessionTimerProps {
  timer: {
    isSessionTimerActive: boolean
    timerTime: string
    timerText: string
    timerBackgroundColor: string
    timerFontColor: string
    backgroundImageActive: boolean
    backgroundImage: {
      coverId: string
      coverUrl: string
    }
  }
  productId: number
  userId: number
}

export const SessionTimer: React.FC<SessionTimerProps> = ({ timer, productId, userId }) => {
  const {
    isSessionTimerActive,
    timerTime,
    timerText,
    timerBackgroundColor,
    timerFontColor,
    backgroundImageActive,
    backgroundImage,
  } = timer || {}

  if (!isSessionTimerActive || timerTime < MIN_TIMER_TIME || !timerText) return null

  const [hours, minutes] = timerTime.split(':')
  const [isTimeOut, setIsTimeOut] = useState(true)
  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

  useEffect(() => {
    const localStorageDate = LocalStorageService.getItem(`session_timer_${productId}${userId ? '_' + userId : ''}`)
    const targetDate = new Date()
    targetDate.setHours(targetDate.getHours() + Number(hours))
    targetDate.setMinutes(targetDate.getMinutes() + Number(minutes))

    if (!localStorageDate) {
      LocalStorageService.setItem(`session_timer_${productId}${userId ? '_' + userId : ''}`, String(targetDate))
    }

    const timerInterval = setInterval(() => {
      setIsTimeOut(false)
      const date = getTimeLeftObject(localStorageDate || targetDate, false, { withoutWeeks: true, withoutDays: true })

      if (date) {
        setTimeLeft(date)
      } else {
        setIsTimeOut(true)
        clearInterval(timerInterval)
      }
    }, 1000)

    return () => {
      clearInterval(timerInterval)
    }
  }, [])

  const imageStyles =
    backgroundImageActive && backgroundImage?.coverUrl
      ? {
          backgroundImage: `url(${backgroundImage.coverUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }
      : {
          backgroundColor: timerBackgroundColor || COLORS.eloBlack,
        }

  const timerStyles = {
    ...imageStyles,
    color: timerFontColor || COLORS.eloWhite,
  }

  if (!isTimeOut) {
    return (
      <div className='session-timer' style={timerStyles}>
        <div>{timerText}</div>
        <div>
          <Time time={timeLeft.hours} />:
          <Time time={timeLeft.minutes} />:
          <Time time={timeLeft.seconds} />
        </div>
      </div>
    )
  }
}
