import React from 'react'
import { components } from 'react-select'

interface Props {
  selectProps?: {
    menuIsOpen?: boolean
  }
}

export const DropdownIndicator: React.FC<Props> = (props) => {
  const { selectProps = {} } = props
  const { menuIsOpen = false } = selectProps

  const iconClassName = menuIsOpen
    ? 'fas fa-sort-up multiple-ticket-date-field__dropdown-indicator--arrow-up-position'
    : 'fas fa-sort-down'

  return (
    <components.DropdownIndicator {...props}>
      <i className={iconClassName} />
    </components.DropdownIndicator>
  )
}
