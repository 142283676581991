import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import { PAYMENT_FORMS, TWO_STEP_PAYMENT_STEPS } from 'constants/paymentSettingShared.constants'
import { ORDER_BUMPS_POSITIONS_TYPES, PAYMENT_PAGE_LAYOUT } from 'constants/paymentPageTemplates.constants'
import { EMBED_TYPES } from 'constants/embeddableItemsShared.constants'
import { PRODUCT_TYPE_IDS, UPSELLS_TYPES } from 'constants/productsShared.constants'
import { PAYMENT_PLANS } from 'constants/pricingPlans.constants'

import PricingPlansList from 'shared/components/pricing-plans/PricingPlansList'
import { useShopStores } from 'shop/hooks/use-store'
import { cleanupHTMLStyleFonts } from 'utils/fonts.utils'

import { EloButton } from '@elo-kit/components/elo-button/EloButton'
import { Purchase } from '../purchase'
import { Summary } from '../summary'
import { Upsells } from '../upsells'
import { PayerForms } from '../payerForms'
import { ShopHeader } from '../header'
import { Tickets } from '../tickets'
import { Attendees } from '../attendees'
import { PurchaseSecure } from '../purchaseSecure'
import { InvoiceBanner } from '../invoice-banner/InvoiceBanner'
import { TermsAndBuyButton } from '../TermsAndBuyButton'
import { SessionTimer } from '../session-timer/SessionBasketTimer'
import { SocialProofBubbleContainer } from '../social-proof-bubble/SocialProofBubble'

interface Props {
  activeUpsellPosition?: string
  orderBumpPreviewMode?: string
  isTemplatePreview?: boolean
  manageLink?: string
  iframeData?: {
    shortDescription: string
    checkoutHtml: string
    checkoutHtmlFooter: string
    pricingPlans: {
      id: number
    }[]
    sessionTimerSettings: {
      isSessionTimerActive: boolean
      timerTime: string
      timerText: string
      timerBackgroundColor: string
      timerFontColor: string
      backgroundImageActive: boolean
      backgroundImage: {
        coverId: string
        coverUrl: string
      }
    }
    free: boolean
  }
  cabinetPreview?: boolean
}

export const TwoStepsAdvanced: React.FC<Props> = observer(function NewTwoSteps(props) {
  const { activeUpsellPosition, orderBumpPreviewMode, isTemplatePreview, manageLink, iframeData } = props
  const {
    paymentStore,
    themeStore,
    shopThemeStore,
    sellerStore,
    productUpsellsStore,
    productSettingsStore,
    userStore,
  } = useShopStores()

  const productSettings = productSettingsStore.item.prefs || {}
  const isEvent = paymentStore.product?.form === PRODUCT_TYPE_IDS.eventTickets
  const isBump = productSettings.upsellType === UPSELLS_TYPES.bump
  const isMainProductRequired = productSettings.mainProductRequired && isBump

  const selectable =
    !isEvent &&
    productUpsellsStore.list.length > 0 &&
    !paymentStore.invoice?.token &&
    (!isMainProductRequired || !isBump)

  const submitFirstStep = () => {
    paymentStore.triggerValidateOnSubmit(true)

    setTimeout(() => {
      const { attendeesAreValid, isPayerFormsFullyValid } = paymentStore
      const payerFormValid = attendeesAreValid && isPayerFormsFullyValid

      if (payerFormValid || paymentStore.invoice?.token) {
        paymentStore.triggerStepChange(TWO_STEP_PAYMENT_STEPS.step2)
      } else {
        setTimeout(paymentStore.highlightInvalidFields, 500)
      }
    }, 300)
  }

  const firstStepIsActive = paymentStore.store?.twoStepActiveStep === TWO_STEP_PAYMENT_STEPS.step1
  const secondStepIsActive = paymentStore.store?.twoStepActiveStep === TWO_STEP_PAYMENT_STEPS.step2

  const isModalEmbed = paymentStore.store?.props?.embedType === EMBED_TYPES.modal
  const wrapperClasses = classNames({
    'embeded-page-wrapper': isModalEmbed,
  })

  const btnStyle = {
    background: themeStore.ppTemplate?.theme?.paymentBuyBtnColor,
    color: themeStore.ppTemplate?.theme?.paymentBuyBtnFontColor,
  }

  const hideInvoicePricingPlans =
    paymentStore.invoice?.token && paymentStore.buildedOrder?.paymentForm === PAYMENT_FORMS.payLater
  const hidePlanSelection = paymentStore.isMainProductSelected && paymentStore.showGroupedPricingPlans
  const plansToShow = paymentStore.getProductPlansToShow()
  const isFree = paymentStore.product?.free
  const oneTimePlan =
    !isFree && plansToShow.length === 1 && plansToShow[0].form === PAYMENT_PLANS.oneTime && plansToShow[0]
  const isOneTimeWithTestPeriod = oneTimePlan && plansToShow[0].testPeriodEnabled

  const showPlans =
    !isEvent &&
    !isFree &&
    (!oneTimePlan || isOneTimeWithTestPeriod) &&
    plansToShow.length > 0 &&
    !hidePlanSelection &&
    !hideInvoicePricingPlans
  const ticketsToShow = paymentStore.invoice?.token ? paymentStore.invoice?.tickets : paymentStore.product?.tickets

  return (
    <div className={wrapperClasses}>
      <SessionTimer
        timer={iframeData?.sessionTimerSettings || paymentStore.product?.sessionTimerSettings}
        productId={paymentStore.product?.id}
        userId={userStore.item?.id}
      />

      <div
        className='fr-view'
        dangerouslySetInnerHTML={{
          __html: cleanupHTMLStyleFonts(iframeData?.checkoutHtml || paymentStore.product?.checkoutHtml) || '',
        }}
      />

      {paymentStore.invoice?.token && <InvoiceBanner order={paymentStore.buildedOrder} manageLink={manageLink} />}

      <ShopHeader isTemplatePreview={isTemplatePreview} iframeData={iframeData} />

      <Upsells
        upsellPosition={ORDER_BUMPS_POSITIONS_TYPES.belowProductDetails}
        activeLayout={PAYMENT_PAGE_LAYOUT.twoSteps}
        activeUpsellPosition={activeUpsellPosition}
        previewMode={orderBumpPreviewMode}
      />

      <div className='widget-wrap mb-4'>
        <div className='steps-container'>
          <div
            onClick={() => {
              secondStepIsActive && paymentStore.triggerStepChange(TWO_STEP_PAYMENT_STEPS.step1)
            }}
            className={classNames('menu-item', { active: firstStepIsActive })}
          >
            <span>{I18n.t('react.shop.common.step', { step: TWO_STEP_PAYMENT_STEPS.step1 })}</span>
            <div className='arrow' />
          </div>
          <div
            onClick={() => firstStepIsActive && submitFirstStep()}
            className={classNames('menu-item', { active: secondStepIsActive })}
          >
            <span>{I18n.t('react.shop.common.step', { step: TWO_STEP_PAYMENT_STEPS.step2 })}</span>
          </div>
        </div>

        <div className={classNames('widget-box', { hidden: secondStepIsActive })}>
          <div className='custom-col-12'>
            <Upsells
              upsellPosition={ORDER_BUMPS_POSITIONS_TYPES.belowPayerDetails}
              activeLayout={PAYMENT_PAGE_LAYOUT.twoSteps}
              activeUpsellPosition={activeUpsellPosition}
              previewMode={orderBumpPreviewMode}
            />

            <PayerForms isTemplatePreview={isTemplatePreview} />
            {paymentStore.proposeAttendees && (
              <Fragment>
                <div className='widget-line' />
                <Attendees />
              </Fragment>
            )}
            <EloButton style={btnStyle} className='full-size regular next-btn' onClick={submitFirstStep}>
              {I18n.t('react.shop.common.next_step')}
            </EloButton>
            {(!paymentStore.store?.free || paymentStore.store?.props?.embedType) && (
              <PurchaseSecure
                isFree={paymentStore.store?.free}
                isEmbeded={paymentStore.store?.props?.embedType}
                themePrefs={shopThemeStore.shopTheme.prefs}
                sellerUsername={sellerStore.item.username}
                sellerAffiliateLandingToken={sellerStore.item.affiliateLandingToken}
                isAppActive={sellerStore.isAppActive}
                ppTemplate={themeStore.ppTemplate}
              />
            )}
          </div>
        </div>

        <div className={classNames('widget-box', { hidden: firstStepIsActive })}>
          <div className='custom-col-12'>
            {!!ticketsToShow?.length && (
              <div className='ticket-step-content'>
                <div className='container-title'>{I18n.t('react.shop.common.events').toUpperCase()}</div>
                <Tickets twoStepsAdvanced />
                <div className='widget-line' />
              </div>
            )}

            {showPlans && (
              <div className='pricing-content'>
                <PricingPlansList
                  pricingPlans={plansToShow}
                  selectedId={paymentStore.store?.product?.planId}
                  forForm={!selectable || paymentStore.isMainProductSelected}
                  keepEnabled
                  coupon={paymentStore.store?.coupon}
                  onSelectPlan={paymentStore.handleProductSelectPlan}
                  hideTitle={paymentStore.invoice?.token}
                />
                <div className='widget-line' />
              </div>
            )}
            <div className='purchase-content'>
              <Purchase withoutTermsAndBuy withWidgetLine iframeFree={iframeData?.free} themeStore={themeStore} />
            </div>
            <Upsells
              upsellPosition={ORDER_BUMPS_POSITIONS_TYPES.belowPaymentMethods}
              activeLayout={PAYMENT_PAGE_LAYOUT.twoSteps}
              activeUpsellPosition={activeUpsellPosition}
              previewMode={orderBumpPreviewMode}
            />
            <Summary isTemplatePreview={isTemplatePreview} iframeFree={iframeData?.free} />
            <div className='purchase'>
              <TermsAndBuyButton upsellItems={null} iframeFree={iframeData?.free} />
            </div>
          </div>
        </div>
      </div>
      <div
        className='fr-view'
        dangerouslySetInnerHTML={{
          __html:
            cleanupHTMLStyleFonts(iframeData?.checkoutHtmlFooter || paymentStore.product.checkoutHtmlFooter) || '',
        }}
      />

      {paymentStore.product.socialProofBubbleActivated && (
        <SocialProofBubbleContainer bubbleData={paymentStore.product.socialProofBubble} />
      )}
    </div>
  )
})
