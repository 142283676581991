import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { get } from 'utils/lodash.utils'
import classNames from 'classnames'

import { ReCaptchaField } from '@elo-kit/components/form/re-captcha-field/ReCaptchaField'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
/* eslint-disable */
import { ReactPayPalScriptOptions } from '@paypal/react-paypal-js/dist/types/types/scriptProviderTypes'
/* eslint-enable */

import { LW_FORBIDDEN_COUNTRIES } from 'constants/countries.constants'
import {
  PAYMENT_FORMS,
  PAYMENT_PROVIDERS,
  STRIPE_SOFORT_ALLOWED_COUNTRIES,
  getDynamicCheckoutCtaText,
} from 'constants/paymentSettingShared.constants'

import { PAYPAL_ENABLED_FUNDINGS, PAYPAL_SDK_BASE_CONFIG } from 'constants/paymentSetting.constants'
import { PAYMENT_PAGE_BUTTON_POSITION_KEYS } from 'constants/paymentPageTemplates.constants'

import { moveToTheEndOfArray } from 'utils/helpersShared.utils'
import { isOnTrialPeriod } from 'utils/seller.utils'

import { PRODUCT_TYPE_IDS, UPSELLS_TYPES } from 'constants/productsShared.constants'
import { isProduction } from 'utils/env.utils'
import { Nullable } from 'types/helpers'
import { useNextRouter } from 'shop/hooks/use-next-js-router'
import { useShopStores } from 'shop/hooks/use-store'
import { TRANSFER_PROVIDERS_BASE } from 'constants/transfers.constants'
import {
  PayPalBuyButtons,
  PaypalMessageCurrencies,
  PaypalPayLaterMessages,
} from 'shop/components/payment/new/paypal-controls/PayPalControls'
import { PurchaseTerms } from './purchaseTerms'
import { PurchaseSecure } from './purchaseSecure'
import { OptInTerms } from './OptInTerms'

interface SearchParams {
  is_preview: string
  cabinet_preview: string
}

interface AllowedPaymentMethods {
  pmList: string[]
  cardProvider: string
  sofortProvider: string
  payerCountry: string
  payLaterDisabled: boolean | string
}

interface Props {
  upsellItems: Nullable<React.ReactElement>
  iframeFree?: boolean
}

const PREVIEW_PAYMENT_METHODS = [
  PAYMENT_FORMS.bankWire,
  PAYMENT_FORMS.sofort,
  PAYMENT_FORMS.paypal,
  PAYMENT_FORMS.payLater,
  PAYMENT_FORMS.card,
  PAYMENT_FORMS.sepa,
]

const getAllowedPaymentMethods = ({
  pmList,
  cardProvider,
  sofortProvider,
  payerCountry,
  payLaterDisabled,
}: AllowedPaymentMethods) => {
  let result = pmList
  const isLemonway = cardProvider === PAYMENT_PROVIDERS.lemonWay
  const isSofortStripe = sofortProvider === PAYMENT_PROVIDERS.stripe
  const isSofortElopageConnect = sofortProvider === PAYMENT_PROVIDERS.elopageConnect
  const isSofortLW = sofortProvider === PAYMENT_PROVIDERS.lemonWay

  if (isLemonway && LW_FORBIDDEN_COUNTRIES.includes(payerCountry)) {
    result = result.filter((name) => name !== PAYMENT_FORMS.card)
  }

  if (
    isSofortLW ||
    ((isSofortStripe || isSofortElopageConnect) && !STRIPE_SOFORT_ALLOWED_COUNTRIES.includes(payerCountry))
  ) {
    result = result.filter((name) => name !== PAYMENT_FORMS.sofort)
  }

  if (payLaterDisabled) {
    result = result.filter((name) => name !== PAYMENT_FORMS.payLater)
  }

  return result
}

export const TermsAndBuyButton: React.FC<Props> = observer(function TermsAndBuyButton(props) {
  const { upsellItems = null, iframeFree } = props
  const { paymentStore, sellerStore, themeStore, shopThemeStore, cancellationTermsStore, productSettingsStore } =
    useShopStores()
  const [totalPrice, setTotalPrice] = useState(paymentStore.totalPrice())
  const activePlan = paymentStore.store?.activePlan

  const {
    params: { is_preview: isPreview, cabinet_preview: isCabinetPreview },
  } = useNextRouter<SearchParams>()
  const recaptchaRef = useRef(null)

  useEffect(() => {
    if (paymentStore.store?.free) {
      paymentStore.setBuyBtnDisabling(false)
    }
  }, [paymentStore.store?.free])

  useEffect(() => {
    setTotalPrice(paymentStore.totalPrice())
  }, [paymentStore.store?.activePlan, paymentStore.totalPrice()])

  const { paypalRestPayLaterEnabled, customStartDay } = activePlan.prefs || {}
  const showPayLater = paypalRestPayLaterEnabled && !customStartDay
  const payerCountry = paymentStore.payerForms?.userData?.payer?.country?.code

  const paypalScriptOptions: ReactPayPalScriptOptions = {
    clientId: sellerStore.item?.paypalClientId,
    enableFunding: showPayLater ? PAYPAL_ENABLED_FUNDINGS : '',
    debug: !isProduction() || isCabinetPreview,
    ...(!isProduction() && (!!isCabinetPreview || !!isPreview) ? { buyerCountry: payerCountry } : {}),
    currency: paymentStore.currency.toUpperCase(),
    ...PAYPAL_SDK_BASE_CONFIG,
  }

  const paymentBuyBtnPosition = themeStore.ppTemplate?.theme?.paymentBuyBtnPosition
  const productOptInQuestions = paymentStore.product?.optIn?.optInQuestions

  const isExistAdditionalFee = (paymentStore.getAdditionalFee() || {}).id
  const commonPaymentMethods = paymentStore.sortedEnabledMethods
  const paymentMethods =
    isExistAdditionalFee && !commonPaymentMethods.length
      ? sellerStore.item.allowedPaymentMethods?.[sellerStore.item.allowedCurrencies?.[0]] || []
      : commonPaymentMethods

  const hasPaymentMethods = paymentMethods.length > 0

  const payLaterDisabled =
    Number(activePlan?.prefs?.testPeriod) > 0 ||
    activePlan?.prefs?.customStartDay ||
    paymentStore.store?.firstPaymentIsFree ||
    paymentStore.invoice?.token ||
    paymentStore.payerForms?.showGift

  const pmList = isPreview && !hasPaymentMethods ? PREVIEW_PAYMENT_METHODS : paymentMethods
  const allowedPaymentMethods = moveToTheEndOfArray(
    getAllowedPaymentMethods({
      pmList,
      cardProvider: sellerStore.item.cardProvider,
      payerCountry,
      sofortProvider: sellerStore.item.sofortProvider,
      payLaterDisabled,
    }),
    PAYMENT_FORMS.payLater
  )

  const freeButtonText = themeStore.ppTemplate?.theme?.paymentCustomFreeBtn
    ? themeStore.ppTemplate?.theme?.paymentGetForFreeBtnText || I18n.t('react.shared.button.take_for_free_v2')
    : I18n.t('react.shared.button.take_for_free_v2')

  const requiredOptInQuestions = productOptInQuestions?.length
    ? productOptInQuestions?.filter((item) => item.mandatory)
    : []
  const optInTermsAccepted =
    !paymentStore.store?.optInsData || requiredOptInQuestions.every(({ id }) => paymentStore.store?.optInsData[id])

  const isBump = get(productSettingsStore, 'item.prefs.upsellType', '') === UPSELLS_TYPES.bump
  const isMainProductRequired = get(productSettingsStore, 'item.prefs.mainProductRequired', false)
  const tickets = Object.values(paymentStore.store?.event?.tickets || [])
  const isAnyTickets = tickets.length > 0
  const isAnyPricingPlanId = isAnyTickets && tickets.map(({ pricingPlanId }: any) => pricingPlanId).some(Boolean)
  const isProductsSelected = paymentStore.isMainProductSelected || paymentStore.hasAnyUpsells || isAnyTickets
  const shouldDisableDueToProduct = isBump
    ? !isProductsSelected || (!paymentStore.isMainProductSelected && isMainProductRequired)
    : !isProductsSelected
  const hasTicketLength = paymentStore.product?.tickets?.length
  const secondRateIsNotFree = paymentStore.store?.firstPaymentIsFree && paymentStore.store?.withNextPayment
  const productIsFree = (iframeFree || paymentStore.store?.free) && !secondRateIsNotFree
  const areHiddenCancellationTerms =
    paymentStore.payerFromType === 'business' && paymentStore.product?.hideB2bCancellationTerm

  const disabled =
    (cancellationTermsStore.item?.checkboxVisible &&
      !productIsFree &&
      !paymentStore.store?.terms &&
      !areHiddenCancellationTerms &&
      !paymentStore.invoice?.token) ||
    (!optInTermsAccepted && !paymentStore.invoice?.token) ||
    isPreview ||
    !paymentStore.paymentAllowed ||
    paymentStore.buyBtnDisabled ||
    shouldDisableDueToProduct ||
    (paymentStore.isMainProductSelected && isAnyPricingPlanId && !allowedPaymentMethods.length) ||
    (!hasTicketLength && !productIsFree && !allowedPaymentMethods.length) ||
    (!paymentStore.store?.paymethods?.form && !productIsFree)
  const btnStyle = themeStore.getBuyBtnStyle(disabled)
  !btnStyle.backgroundColor && delete btnStyle.backgroundColor // TODO: SSR - check why empty string make hydration error

  const showTicketPurchaseWarning =
    paymentStore.product?.form === PRODUCT_TYPE_IDS.eventTickets && isBump && isMainProductRequired && !isAnyTickets

  const buyButtonClasses = classNames('elo-btn large orange btn-block purchase__buy-button', {
    'purchase__buy-button--with-margin':
      paymentBuyBtnPosition === PAYMENT_PAGE_BUTTON_POSITION_KEYS.belowPaymentOptions ||
      (!productOptInQuestions?.length && paymentBuyBtnPosition === PAYMENT_PAGE_BUTTON_POSITION_KEYS.belowCustomFields),
  })

  const isDynamicButton = themeStore.ppTemplate?.theme?.paymentBuyBtnText === 'dynamic_cta'
  const buyBtnText = {
    buy_now: I18n.t('react.shared.button.buy_now'),
    agree_on_terms_and_pay: I18n.t('react.shared.button.agree_on_terms_and_pay'),
    complete_order: I18n.t('react.shared.button.complete_order'),
    accept_and_pay_now: I18n.t('react.shared.button.accept_and_pay_now'),
  }
  const dynamicTextButton = isDynamicButton
    ? getDynamicCheckoutCtaText()[paymentStore.store?.paymethods?.form] || I18n.t('react.shared.button.buy_now')
    : buyBtnText[themeStore.ppTemplate?.theme?.paymentBuyBtnText] || buyBtnText['buy_now']

  const buyButtonText = productIsFree ? freeButtonText : dynamicTextButton

  const showCaptcha = productIsFree && isOnTrialPeriod(sellerStore.item.planId) && sellerStore.item.planTrial

  const handleSubmitWithRecaptcha = () =>
    showCaptcha
      ? () =>
          recaptchaRef?.current?.executeAsync().then((resp) => {
            if (!!resp) {
              recaptchaRef.current.reset()
              return paymentStore.handleSubmit()
            }
          })
      : () => paymentStore.handleSubmit() as Promise<string>

  const defaultButton = () => (
    <button
      style={btnStyle}
      className={buyButtonClasses}
      onClick={showTicketPurchaseWarning ? () => {} : handleSubmitWithRecaptcha()}
      disabled={disabled}
    >
      {paymentStore.invoice?.token ? I18n.t('react.shared.button.pay_now') : buyButtonText}
    </button>
  )

  const buyButton = () => {
    const { paypalProvider, paypalRestV2 } = sellerStore.item

    if (
      paymentStore.store?.paymethods?.form === PAYMENT_FORMS.paypal &&
      paypalProvider === TRANSFER_PROVIDERS_BASE.paypalRest &&
      paypalRestV2 &&
      !productIsFree
    ) {
      return (
        <>
          <PayPalBuyButtons
            paymentStore={paymentStore}
            paypalScriptOptions={paypalScriptOptions}
            buyButtonDisabled={disabled || showTicketPurchaseWarning}
            submit={handleSubmitWithRecaptcha()}
            style={{ disableMaxWidth: true }}
            isPreview={{
              isCabinetPreview: !!isCabinetPreview,
              isPreview: !!isPreview,
            }}
          >
            <>{defaultButton()}</>
          </PayPalBuyButtons>
          <br />
          {showPayLater && (
            <PaypalPayLaterMessages
              currency={paypalScriptOptions.currency as PaypalMessageCurrencies}
              amount={totalPrice}
              payerCountry={paymentStore.payerCountry}
              isPreview={!!isCabinetPreview}
            />
          )}
        </>
      )
    }

    return <>{defaultButton()}</>
  }

  const purchaseWarningAlert = showTicketPurchaseWarning && (
    <div className='purchase__warning-alert'>
      <div>
        <i className='fas fa-exclamation-circle purchase__warning-alert--icon' />
      </div>
      <div className='purchase__warning-alert--message'>{I18n.t('react.shop.payment.form.ticket_alert')}</div>
    </div>
  )

  return (
    <>
      <PayPalScriptProvider options={paypalScriptOptions} deferLoading={false}>
        {!sellerStore.item.powerSeller &&
          paymentBuyBtnPosition === PAYMENT_PAGE_BUTTON_POSITION_KEYS.belowPaymentOptions && (
            <>
              {upsellItems}
              {buyButton()}
            </>
          )}

        {!!productOptInQuestions?.length && !paymentStore.invoice?.token && (
          <OptInTerms
            questions={productOptInQuestions}
            onInputChange={(id, value) => paymentStore.handleOptInChange(id, value)}
            formData={paymentStore.store?.optInsData}
            forceDirty={paymentStore.store?.validateOnSubmit}
            paymentPageColor={themeStore.ppTemplate?.theme?.paymentPageColor}
          />
        )}

        {!sellerStore.item.powerSeller &&
          paymentBuyBtnPosition === PAYMENT_PAGE_BUTTON_POSITION_KEYS.belowCustomFields && (
            <>
              {upsellItems}
              {buyButton()}
            </>
          )}

        <PurchaseTerms
          isFree={productIsFree}
          link={paymentStore.product?.cancelLink}
          htmlTerms={cancellationTermsStore.item}
          checked={paymentStore.store?.terms}
          onCheckboxChange={(terms) => paymentStore.submitTerms(terms)}
          productType={paymentStore.product?.form}
          invoiceToken={paymentStore.invoice?.token}
          areHiddenCancellationTerms={areHiddenCancellationTerms}
          sellerUsername={sellerStore.item.username}
          isPowerSeller={sellerStore.item.powerSeller}
          usePowerSellerDocument={sellerStore.item.documentConfiguration?.termsOfBusiness}
          paymentPageColor={themeStore.ppTemplate?.theme?.paymentPageColor}
        />

        {(!paymentBuyBtnPosition ||
          sellerStore.item.powerSeller ||
          paymentBuyBtnPosition === PAYMENT_PAGE_BUTTON_POSITION_KEYS.belowLegalText) && (
          <>
            {upsellItems}
            {purchaseWarningAlert}
            {buyButton()}
          </>
        )}

        <div className='clearfix' />

        {(!productIsFree || paymentStore.store?.props?.embedType) && (
          <PurchaseSecure
            isFree={productIsFree}
            isEmbeded={paymentStore.store?.props?.embedType}
            themePrefs={shopThemeStore.shopTheme.prefs}
            sellerUsername={sellerStore.item.username}
            sellerAffiliateLandingToken={sellerStore.item.affiliateLandingToken}
            isAppActive={sellerStore.isAppActive}
            ppTemplate={themeStore.ppTemplate}
          />
        )}

        {showCaptcha && <ReCaptchaField recaptchaRef={recaptchaRef} locale={I18n.currentLocale()} />}
      </PayPalScriptProvider>
    </>
  )
})
